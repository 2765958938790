import React, { useState, useEffect } from "react";
import anime from "animejs/lib/anime.es.js";
import Bgline from "../Background/Bgline";
import Work from "./Work";

const AboutUs = () => {
  useEffect(() => {
    anime({
      targets: ".help-heading",
      translateX: [-1900, 0],
      opacity: 1,
      duration: 2000,
      easing: "easeInOutExpo",
    });
    anime({
      targets: ".help-info a",
      translateY: [500, 0],
      duration: 2000,
      delay: function (el, i, l) {
        return i * 400;
      },
      endDelay: function (el, i, l) {
        return (l - i) * 400;
      },
      easing: "easeInOutExpo",
    });
  }, []);
  return (
    <>
      <Bgline />
      <section className="help">
        <div className="help-heading">
          <h3 className="heading">Nasza oferta</h3>

          <p className="text">
            Nasza agencja marketingowa w Poznaniu oferuje kompleksowe usługi
            związane z tworzeniem i projektowaniem stron internetowych oraz
            kreowaniem wizerunku marki w internecie. Dzięki naszemu
            doświadczeniu w projektowaniu marek oraz optymalizacji stron
            internetowych, jesteśmy w stanie stworzyć angażujące i profesjonalne
            strony, które skutecznie przyciągną uwagę użytkowników.
          </p>
        </div>
        <div className="help-info">
          <a href="/portfolio">
            <div className="projekty-i-wdrożenia-stron-www">
              <p className="text"> Tworzenie stron internetowych</p>
            </div>
          </a>
          <a href="/portfolio">
            <div className="projekty-id">
              <p className="text">Projektowanie graficzne</p>
            </div>
          </a>
          <a href="/portfolio">
            <div className="projekty-logo">
              <p className="text">Tworzenie loga</p>
            </div>
          </a>

          <a href="/portfolio">
            <div className="projekty-aniamcje">
              <p className="text">Tworzenie animacji</p>
            </div>
          </a>
        </div>
      </section>
      <Work />
    </>
  );
};

export default AboutUs;
