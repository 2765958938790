import React, { useEffect } from "react";
import anime from "animejs/lib/anime.es.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

const MoreOffer = () => {
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: ".more-offer",
      start: "top 40%",
      markers: false,
      scrub: 3,
      once: true,
      onEnter: () => {
        anime({
          targets: ".more-offer",
          translateY: [200, 0],
          opacity: 1,
          duration: 2000,
          easing: "easeInOutExpo",
        });

        anime({
          targets: ".more-offer .help-info a",
          translateY: [200, 0],
          opacity: 1,
          duration: 2000,
          delay: function (el, i, l) {
            return i * 200;
          },
          endDelay: function (el, i, l) {
            return (l - i) * 200;
          },
          easing: "easeInOutExpo",
        });
      },
    });
  }, []);

  return (
    <>
      <section className="more-offer">
        <div className="heading-small-project">
          <p>NASZA OFERTA</p>
          <h2>Zobacz więcej naszych usług</h2>
          <hr></hr>
        </div>

        <div className="help-info">
          <a href="/oferta/projektowanie-graficzne">
            <div className="projekty-id">
              <h2>Projektowanie graficzne</h2>
              <p>
                Nasza agencja oferuje kreatywne i profesjonalne projektowanie
                graficzne, które skutecznie przekazuje przekaz marki.
                Dopasowujemy nasze projekty do celów biznesowych klienta, dążąc
                do maksymalizacji efektów marketingowych.
              </p>
            </div>
          </a>
          <a href="/oferta/tworzenie-logo">
            <div className="projekty-logo">
              <h2>Tworzenie loga</h2>
              <p>
                Nasz zespół projektuje unikalne loga, które doskonale oddają
                charakter Twojej marki. Dzięki naszej kreatywności i precyzji,
                tworzymy logo, które przyciąga uwagę i wyróżnia się w tłumie,
                pomagając budować rozpoznawalność Twojego biznesu.
              </p>
            </div>
          </a>

          <a href="/oferta/tworzenie-stron-internetowych">
            <div className="projekty-i-wdrożenia-stron-www">
              <h2>Tworzenie stron internetowych</h2>
              <p>
                Tworzenie stron internetowych to sztuka łączenia kreatywności z
                funkcjonalnością. Nasz zespół specjalistów zajmuje się
                projektowaniem i programowaniem stron dopasowanych do unikalnych
                potrzeb klientów.
              </p>
            </div>
          </a>
        </div>
      </section>
    </>
  );
};

export default MoreOffer;
