import React, { useEffect, useState, useMemo, useCallback } from "react";
import { FloatingBanner } from "./FloatingBanner";
import Cookies from "universal-cookie";
import PropTypes from "prop-types";

export function ConsentForm({ color }) {
  const [decisionMade, setDecisionMade] = useState(true); // start with true to avoid flashing
  const cookies = useMemo(() => new Cookies(), []);

  function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  const sendConsent = useCallback((consent) => {
    gtag("consent", "default", consent);
  }, []);

  useEffect(() => {
    if (cookies.get("DESKARCOOKIE") !== undefined) {
      setDecisionMade(true);
    } else {
      setDecisionMade(false);
    }
  }, [cookies, setDecisionMade, sendConsent]);

  const handleDecision = (outcome) => {
    const consent = {
      ad_storage: outcome,
      analytics_storage: outcome,
      ad_user_data: outcome,
      ad_personalization: outcome,
    };

    cookies.set("DESKARCOOKIE", consent, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      path: "/",
      domain: "deskar.pl",
    });

    sendConsent(consent);
    setDecisionMade(true);
  };

  return decisionMade ? (
    <></>
  ) : (
    <FloatingBanner
      color={color}
      header="Dbamy o prywatność użytkowników"
      message="Używamy plików cookie, aby poprawić jakość przeglądania, wyświetlać reklamy lub treści dostosowane do indywidualnych potrzeb użytkowników oraz analizować ruch na stronie. Kliknięcie przycisku „Akceptuj” oznacza zgodę na wykorzystywanie przez nas plików cookie."
      acceptText="Akceptuj"
      denyText="Odrzuć"
      onAccept={() => {
        handleDecision("granted");
      }}
      onDeny={() => {
        handleDecision("denied");
      }}
    />
  );
}

ConsentForm.propTypes = {
  color: PropTypes.string.isRequired,
};

ConsentForm.defaultProps = {
  color: "blue",
};
