import React from "react";
import PropTypes from "prop-types";

export function FloatingBanner({
  color,
  message,
  header,
  acceptText,
  denyText,
  onAccept,
  onDeny,
}) {
  return (
    <div className="floating-banner">
      <div className="floating-banner__container">
        <div className={`floating-banner__content bg-${color}-600`}>
          <div className="floating-banner__inner">
            <div className="floating-banner__text">
              <div className={`floating-banner__header text-${color}-300`}>
                {header}
              </div>
              <div className="floating-banner__message text-white">
                <span className="md-hidden">{message}</span>
                <span className="md-inline">{message}</span>
              </div>
            </div>
            <div className="floating-banner__buttons">
              {acceptText && (
                <div className="floating-banner__button-container">
                  <div className="rounded-md shadow-sm">
                    {onAccept && (
                      <button
                        className={`floating-banner__button floating-banner__button--accept text-${color}-600 hover:text-${color}-500`}
                        onClick={onAccept}
                      >
                        {acceptText}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {denyText && (
                <div className="floating-banner__button-container">
                  <button
                    className={`floating-banner__button floating-banner__button--deny text-${color}-200 hover:text-${color}-500 border-${color}-500`}
                    onClick={onDeny}
                  >
                    {denyText}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

FloatingBanner.propTypes = {
  color: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  acceptText: PropTypes.string.isRequired,
  denyText: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDeny: PropTypes.func.isRequired,
};

FloatingBanner.defaultProps = {
  color: "blue",
};
