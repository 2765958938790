import React, { useState, useEffect } from "react";
import anime from "animejs/lib/anime.es.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Counter from "./Counter";
import { useParams } from "react-router-dom";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const About = () => {
  const [idCount, setCount] = useState(0);
  const [awCount, setAwCount] = useState(0);
  const [expCount, setExpCount] = useState(0);

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: ".about", // Trigger element
      start: "top 40%", // Start point of the trigger
      markers: false, // Whether to display debug markers
      scrub: 3, // Whether animation should scrub or not
      once: true,
      onEnter: () => {
        anime({
          targets: ".heading-small, .about-left",
          translateY: [200, 0],
          opacity: 1,
          duration: 1000,
          easing: "easeInOutExpo",
        });
      },
    });

    ScrollTrigger.create({
      trigger: ".about", // Trigger element
      start: "top 40%", // Start point of the trigger
      markers: false, // Whether to display debug markers
      scrub: 3, // Whether animation should scrub or not
      once: true,
      onEnter: () => {
        anime({
          targets: ".about-right p",
          translateY: [200, 0],
          opacity: 1,
          duration: 1000,
          delay: function (el, i, l) {
            return i * 200;
          },
          endDelay: function (el, i, l) {
            return (l - i) * 200;
          },
          easing: "easeInOutExpo",
        });
      },
    });

    ScrollTrigger.create({
      trigger: ".about", // Trigger element
      start: "top 40%", // Start point of the trigger
      markers: false, // Whether to display debug markers
      scrub: 3, // Whether animation should scrub or not
      once: true,
      onEnter: () => {
        anime({
          targets: ".about-button",
          translateY: [200, 0],
          opacity: 1,
          duration: 1000,
          delay: 500,
          easing: "easeInOutExpo",
        });
      },
    });

    ScrollTrigger.create({
      trigger: ".counter", // Trigger element
      start: "top 70%", // Start point of the trigger
      markers: false, // Whether to display debug markers
      scrub: 3, // Whether animation should scrub or not
      once: true,
      onEnter: () => {
        anime({
          targets: ".aw .counter-des hr, .aw .number",
          translateX: [-1500, 0],
          opacity: 1,
          duration: 1500,
          easing: "easeInOutExpo",
        });
      },
    });

    ScrollTrigger.create({
      trigger: ".counter", // Trigger element
      start: "top 70%", // Start point of the trigger
      markers: false, // Whether to display debug markers
      scrub: 3, // Whether animation should scrub or not
      once: true,
      onEnter: () => {
        anime({
          targets:
            ".exp .counter-des hr,  .id  .counter-des hr, .exp  .number, .id .number, .id .plus",
          translateX: [1500, 0],
          opacity: 1,
          duration: 1500,
          easing: "easeInOutExpo",
        });
      },
    });

    ScrollTrigger.create({
      trigger: ".counter", // Trigger element
      start: "top 60%", // Start point of the trigger
      markers: false, // Whether to display debug markers
      scrub: 3, // Whether animation should scrub or not
      once: true,
      onEnter: () => {
        anime({
          targets:
            ".exp .counter-des p, .id  .counter-des p, .aw .counter-des  p",
          translateY: [20, 0],
          opacity: 1,
          duration: 1500,
          delay: 500,
          easing: "easeInOutExpo",
        });
      },
    });
  }, []);

  return (
    <>
      <section className="about" id="about">
        <div className="heading-small">
          <p className="small-text">O NAS</p>
        </div>
        <div className="about-main">
          <div className="about-left">
            <h2 className="heading">Deskar</h2>
          </div>
          <div className="about-right">
            <p className="text">
              Jesteśmy Deskar, specjalizujemy się w{" "}
              <a href="/oferta/tworzenie-stron-internetowych">
                tworzeniu stron internetowych
              </a>{" "}
              w Poznaniu, które nie tylko przyciągają uwagę wizualną, ale
              również gwarantują efektywność i doskonałe wrażenia użytkownika.
            </p>
            <p className="text">
              6 lat doświadczenia przy{" "}
              <a href="/oferta/tworzenie-stron-internetowych">
                tworzeniu oraz projektowaniu stron internetowych
              </a>{" "}
              <br></br> w Poznaniu, pozwoliły nam opracować procesy, które
              skutecznie pomogają tworzyć nowe marki i ulepszać już istniejące.
            </p>
            <p className="small-text">
              Jesteśmy przekonani, że projektowanie, które wywodzi się z funkcji
              i przenika głęboką ideą, stanowi fundament dla tworzenia mocnych,
              intrygujących oraz angażujących marek.
            </p>
            <div className="about-button">
              <a href="/o-nas" className="small-text">
                ZOBACZ JAK DZIAŁAMY
              </a>
            </div>
          </div>
        </div>
      </section>
      <Counter idCount={idCount} expCount={expCount} awCount={awCount} />
    </>
  );
};

export default About;
