const MockUpVideo = () => {
  return (
    <section className="mock-up-video">
      <video
        src="/photos/mockUpVideo.mp4"
        alt="project video"
        autoPlay
        muted
        loop
      ></video>
    </section>
  );
};

export default MockUpVideo;
