const Bgline = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1580.41 812.17"
      className="bgline"
    >
      <defs></defs>
      <path
        className="cls-1"
        d="M978.83,602.19c40,6,80.46-8.08,109.08-36.7h0c19.95-20,118.35-74.31,0-74.31s-142.44-48.85-191.29,0-22.71,87.39,0,110.09,37.16,0,70.18,0A82.49,82.49,0,0,1,978.83,602.19Z"
        transform="translate(-216.88 -134.33)"
      />
      <path
        className="cls-1"
        d="M964,634.57c61,9.08,122.62-12.32,166.23-55.93h0c30.41-30.41,180.37-113.25,0-113.25s-217.07-74.46-291.53,0-34.61,133.18,0,167.79,56.63,0,107,0A125,125,0,0,1,964,634.57Z"
        transform="translate(-216.88 -134.33)"
      />
      <path
        className="cls-1"
        d="M949.22,666.94c82,12.21,164.78-16.55,223.4-75.16h0c40.87-40.87,242.39-152.2,0-152.2s-291.72-100.06-391.78,0-46.51,179,0,225.48,76.1,0,143.75,0A167.34,167.34,0,0,1,949.22,666.94Z"
        transform="translate(-216.88 -134.33)"
      />
      <path
        className="cls-1"
        d="M934.41,699.32c103,15.33,206.95-20.79,280.56-94.4h0c51.33-51.33,304.42-191.15,0-191.15s-366.36-125.66-492,0S664.54,638.55,723,697s95.57,0,180.53,0A209.61,209.61,0,0,1,934.41,699.32Z"
        transform="translate(-216.88 -134.33)"
      />
      <path
        className="cls-1"
        d="M919.61,731.69c123.94,18.46,249.11-25,337.72-113.63h0c61.78-61.78,366.44-230.09,0-230.09s-441-151.26-592.27,0-70.31,270.57,0,340.87,115,0,217.31,0A253.59,253.59,0,0,1,919.61,731.69Z"
        transform="translate(-216.88 -134.33)"
      />
      <path
        className="cls-1"
        d="M904.8,764.07c144.93,21.58,291.28-29.26,394.88-132.87h0c72.25-72.24,428.47-269,0-269S784,185.3,607.17,362.17s-82.21,316.36,0,398.57,134.52,0,254.09,0A295.86,295.86,0,0,1,904.8,764.07Z"
        transform="translate(-216.88 -134.33)"
      />
      <path
        className="cls-1"
        d="M890,796.44c165.9,24.71,333.44-33.49,452-152.1h0c82.7-82.69,490.49-308,0-308s-590.29-202.46-792.76,0-94.11,362.17,0,456.27,154,0,290.87,0A338.48,338.48,0,0,1,890,796.44Z"
        transform="translate(-216.88 -134.33)"
      />
      <path
        className="cls-1"
        d="M875.19,828.82c186.88,27.83,375.6-37.73,509.21-171.33h0c93.15-93.16,552.5-346.93,0-346.93s-664.94-228.07-893,0-106,408,0,514,173.46,0,327.65,0A380.75,380.75,0,0,1,875.19,828.82Z"
        transform="translate(-216.88 -134.33)"
      />
      <path
        className="cls-1"
        d="M860.38,861.19c207.86,31,417.77-42,566.37-190.56h0c103.61-103.62,614.53-385.87,0-385.87s-739.58-253.67-993.25,0-117.91,453.75,0,571.66,192.93,0,364.43,0A424.49,424.49,0,0,1,860.38,861.19Z"
        transform="translate(-216.88 -134.33)"
      />
      <path
        className="cls-1"
        d="M845.58,893.57c228.83,34.08,459.93-46.2,623.53-209.8h0c114.07-114.07,676.55-424.81,0-424.81S654.88-20.32,375.61,259s-129.81,499.54,0,629.35,212.4,0,401.21,0A467,467,0,0,1,845.58,893.57Z"
        transform="translate(-216.88 -134.33)"
      />
    </svg>
  );
};

export default Bgline;
