const Bglineproject = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1675.73 2005.74"
      className="bglineproject"
    >
      <path
        className="cls-1"
        d="M748.78,1066.89c-18.65,10-27.27,19.52-23.06,24.53,3.72,5.52,20.27,6.53,40.75,11.41,20.82,4.64,40.49,16.07,62.66,40.53,22.44,23.67,47.39,60.37,85.23,97.22,37.86,37.56,71.42,64.29,93.26,77.74,23,13.43,34.27,13.59,54.1-2.77,18.22-14.44,42.25-51.78,49.84-97.38,9-45.29,1.47-98.84-11.84-138.42-13.39-41.65-21.58-71.11-31.46-99.72-9.78-27.95-21.23-55.06-39-76.83-36.15-44.73-82-56.66-103.18-26.23-22.71,29.56-30.67,94-60.74,126.41C838.39,1037.64,787,1045.77,748.78,1066.89Z"
        transform="translate(-62.65 -199.57)"
      />
      <path
        className="cls-1"
        d="M684.17,1069c-25.46,13.64-37.22,26.64-31.48,33.48,5.08,7.54,27.66,8.91,55.61,15.58,28.42,6.32,55.26,21.92,85.5,55.3,30.64,32.3,64.68,82.38,116.32,132.66,51.66,51.27,97.45,87.73,127.26,106.1,31.37,18.32,46.76,18.54,73.82-3.78,24.87-19.7,57.66-70.66,68-132.89,12.22-61.81,2-134.88-16.16-188.9-18.26-56.84-29.45-97-42.92-136.08-13.35-38.14-29-75.12-53.24-104.84-24-29.39-52.86-50.21-78.18-56.54-25.53-6.69-47.49,1.11-62.62,20.75-31,40.33-41.85,128.22-82.89,172.49C806.45,1029.12,736.25,1040.21,684.17,1069Z"
        transform="translate(-62.65 -199.57)"
      />
      <path
        className="cls-1"
        d="M619.55,1071.18c-32.26,17.27-47.17,33.75-39.89,42.42,6.44,9.55,35.06,11.29,70.48,19.74,36,8,70,27.79,108.34,70.09,38.82,40.93,82,104.38,147.4,168.1,65.46,65,123.49,111.17,161.26,134.45,39.76,23.21,59.26,23.48,93.55-4.8,31.5-25,73.06-89.54,86.19-168.4,15.49-78.31,2.54-170.91-20.48-239.36-23.14-72-37.32-123-54.39-172.44-16.92-48.34-36.73-95.2-67.46-132.86-30.39-37.25-67-63.63-99.07-71.65-32.35-8.47-60.18,1.41-79.35,26.3-19.79,24.55-32.86,66.5-47.73,107.58-14.83,41.41-31.45,81.95-57.31,111C774.5,1020.6,685.54,1034.66,619.55,1071.18Z"
        transform="translate(-62.65 -199.57)"
      />
      <path
        className="cls-1"
        d="M554.93,1073.33c-39.06,20.91-57.11,40.87-48.3,51.36,7.8,11.57,42.45,13.67,85.34,23.9,43.6,9.7,84.78,33.65,131.19,84.87,47,49.56,99.24,126.4,178.47,203.55,79.28,78.66,149.54,134.61,195.27,162.79,48.14,28.11,71.75,28.44,113.28-5.81,38.14-30.22,88.47-108.41,104.36-203.9,18.75-94.83,3.07-206.95-24.8-289.84-28-87.21-45.19-148.89-65.86-208.8-20.48-58.52-44.47-115.27-81.69-160.87-36.79-45.1-81.11-77-120-86.76-39.17-10.26-72.87,1.71-96.09,31.84-23.95,29.74-39.78,80.53-57.79,130.28-18,50.13-38.08,99.22-69.39,134.4C742.56,1012.08,634.84,1029.1,554.93,1073.33Z"
        transform="translate(-62.65 -199.57)"
      />
      <path
        className="cls-1"
        d="M490.31,1075.47c-45.86,24.56-67.05,48-56.71,60.31,9.16,13.58,49.84,16.06,100.2,28.06,49.27,11.67,101.41,39.24,154.17,99.63,53.19,58.48,118.31,148.15,209.42,239,44.2,45.44,90.27,85.55,129.6,117.84,39.71,32.37,72.69,56.93,99.82,73.37,54.27,32,86.27,34.27,132.85-6.88,44.79-35.49,103.88-127.3,122.54-239.41,22-111.35,3.61-243-29.12-340.31-32.9-102.4-53.06-174.83-77.33-245.16-24-68.72-52.21-135.35-95.91-188.89-43.19-52.95-95.23-90.45-140.84-101.86-46-12-85.56,2-112.82,37.38-28.13,34.91-46.71,94.55-67.85,153C837.25,820.38,813.61,878,776.85,919.33c-34.17,42.71-81,68-132.75,90.09C592.64,1031.34,536.17,1050.11,490.31,1075.47Z"
        transform="translate(-62.65 -199.57)"
      />
      <path
        className="cls-1"
        d="M425.7,1077.62c-52.67,28.2-77,55.11-65.12,69.25,10.5,15.6,57.23,18.44,115.05,32.23,56.58,13.39,116.45,45.06,177,114.4,61.08,67.15,135.86,170.13,240.48,274.46,50.76,52.17,103.66,98.23,148.81,135.31,45.61,37.18,83.47,65.38,114.63,84.25,62.32,36.79,99.06,39.35,152.56-7.9,52.73-42.8,118-144.18,140.88-274.88,12-64.47,14.23-134.35,6.88-201.52-7-67.31-23.52-131.92-40.49-189.3-37.78-117.58-60.92-200.75-88.8-281.52C1300,753.49,1267.67,677,1217.49,615.5c-49.6-60.8-109.36-103.87-161.73-117-52.82-13.83-98.25,2.3-129.55,42.93C893.91,581.55,872.57,650,848.29,717.1c-24.21,67.6-51.35,133.79-93.56,181.22-39.24,49-93,78.06-152.44,103.45C543.2,1027,478.36,1048.49,425.7,1077.62Z"
        transform="translate(-62.65 -199.57)"
      />
      <path
        className="cls-1"
        d="M361.14,1079.78c-57.75,32-87.46,61.87-73.95,78.29,11.94,17.68,66.06,20.51,130,36.25,65.06,15.5,131.19,51,199.89,128.91,68.63,75.71,154.71,193.81,271.69,310.16a2244.21,2244.21,0,0,0,167.53,152.38c51.6,42.32,95.57,74.6,130.37,95.69,69.81,42,110.86,42.74,172.12-8.78,58.64-50,132.8-162.68,159.28-310.35,29.12-147,1.39-313.68-38.41-441.51-39.36-133.3-71.06-226.58-100.29-317.65C1349.62,713.65,1311,627.24,1254.92,558c-55.09-69.1-123.37-117.27-182.71-132.13-60.07-15.45-109,1.89-146,48.4-37.67,46.2-60.29,122.06-87.7,198.29-27,76.64-59.47,151-105.61,204.69C687.31,932,628,966.26,560.79,994.13,494.33,1021.74,419.11,1047.93,361.14,1079.78Z"
        transform="translate(-62.65 -199.57)"
      />
      <path
        className="cls-1"
        d="M296.53,1081.93c-64.36,35.66-97.47,69-82.41,87.25,13.31,19.7,73.61,22.85,144.89,40.4,72.51,17.26,146.2,56.79,222.76,143.65,76.47,84.36,172.4,216,302.77,345.64,63.89,64.59,129.54,122.79,186.69,169.81,57.5,47.16,106.5,83.12,145.28,106.63,77.79,46.83,123.54,47.63,191.8-9.78,65.35-55.71,148-181.3,177.5-345.86,32.46-163.78,1.56-349.55-42.79-492-43.88-148.55-79.2-252.49-111.77-354-33.18-99.76-76.21-196.06-138.71-273.18-61.39-77-137.47-130.68-203.6-147.25-67-17.21-121.45,2.11-162.72,53.94-42,51.48-67.18,136-97.73,221-30.07,85.41-66.27,168.32-117.69,228.1C660,917.26,594,955.42,519,986.48,445,1017.25,361.13,1046.44,296.53,1081.93Z"
        transform="translate(-62.65 -199.57)"
      />
      <path
        className="cls-1"
        d="M231.92,1084.08c-71,39.32-107.47,76-90.87,96.2,14.68,21.72,81.17,25.19,159.77,44.55,79.94,19,161.2,62.62,245.62,158.39,43.13,46.94,89.37,105.91,144.48,171.76,54.8,65.71,118.46,138.3,189.36,209.36,70.45,71.22,142.85,135.4,205.86,187.24,63.4,52,117.43,91.66,160.19,117.57,85.77,51.65,136.22,52.52,211.49-10.78,72-61.42,163.19-199.91,195.72-381.36,35.78-180.59,1.71-385.43-47.19-542.5C1558,970.71,1519,856.1,1483.11,744.19c-36.58-110-84-216.18-152.95-301.22-67.69-84.91-151.58-144.1-224.5-162.36-73.82-19-133.91,2.33-179.42,59.48-46.28,56.76-74.08,150-107.76,243.65-33.16,94.17-73.07,185.59-129.77,251.51-56,67.25-128.83,109.33-211.47,143.58C395.58,1012.76,303.16,1044.94,231.92,1084.08Z"
        transform="translate(-62.65 -199.57)"
      />
      <path
        className="cls-1"
        d="M167.32,1086.23c-77.58,43-117.48,83.1-99.34,105.16,16,23.74,88.72,27.53,174.64,48.69,42.73,10.93,88.05,27.34,132.57,55C420,1322.62,464,1361.4,511.11,1413.22,558.25,1464.52,608.8,1529,669,1601c59.91,71.83,129.49,151.17,207,228.85,77,77.85,156.14,148,225,204.67,69.31,56.84,128.36,100.19,175.11,128.52,93.76,56.45,148.9,57.41,231.17-11.79,39.63-34.55,83.9-87.88,123.13-159.06,39.16-70.88,73.29-159.6,90.81-257.79,18.63-97.71,20.33-204.35,9.08-306.31-11-102.27-34.86-199.86-60.66-286.7C1616.8,962.3,1574.23,837,1535,714.7c-40-120.25-91.85-236.3-167.19-329.26-74-92.82-165.69-157.51-245.4-177.47-80.69-20.76-146.37,2.54-196.11,65-50.6,62-81,163.94-117.8,266.33C772.23,642.25,728.6,742.18,666.62,814.23,605.41,887.75,525.8,933.75,435.47,971.18,346.2,1008.27,245.18,1043.45,167.32,1086.23Z"
        transform="translate(-62.65 -199.57)"
      />
    </svg>
  );
};

export default Bglineproject;
