import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import UseFetchProjects from "../UseFetch/UseFetchProjects";
import anime from "animejs/lib/anime.es.js";
import Bgline from "../Background/Bgline";
import { Helmet, HelmetProvider } from "react-helmet-async";
import metaLogo from "../../assets/metalogo.png";

const SingleProject = () => {
  const { id } = useParams();
  const { data, error } = UseFetchProjects(
    `https://db-json-gamma.vercel.app/project/${id}`
  );

  useEffect(() => {
    if (data) {
      anime({
        targets: ".singleProject h2,  .category",
        translateX: [-1400, 0],
        opacity: 1,
        duration: 2000,
        easing: "easeInOutExpo",
      });

      anime({
        targets: ".date",
        translateX: [1000, 0],
        opacity: 1,
        duration: 2000,
        easing: "easeInOutExpo",
      });

      anime({
        targets: ".singleProject-mockups",
        translateY: [800, 0],
        opacity: 1,
        duration: 2000,
        easing: "easeInOutExpo",
      });
    }
  }, [data]);

  return (
    <>
      <Bgline />
      {error && <div>Error: {error}</div>}
      {data && (
        <>
          <HelmetProvider>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{`Deskar - ${data.title} - Strony internetowe Poznań`}</title>
              <meta
                name="robots"
                content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
              ></meta>
              <meta
                name="viewport"
                content="width=device-width, minimum-scale=1.0"
              />
              <meta
                name="description"
                content={`${data.description.slice(0, 100)}`}
              />
              <meta
                name="keywords"
                content="Deskar, strony internetowe, projektowanie stron, tworzenie stron, Karwacki, strony wwww, projektowanie graficzne, loga, projektowanie loga, idetyfikacja wizualna, wizytówki, projektowanie wizytówki"
              />
              <link
                rel="canonical"
                aria-label="deskar.pl"
                href={`https://deskar.pl${data.link}`}
              ></link>
              <meta property="og:locale" content="pl_PL" />
              <meta property="og:type" content="website" />
              <meta
                property="og:title"
                content={`Deskar - ${data.title} - Strony internetowe Poznań`}
              />
              <meta
                property="og:description"
                content={`${data.description.slice(0, 100)}`}
              ></meta>
              <meta
                property="og:url"
                content={`https://deskar.pl${data.link}`}
              />
              <meta
                property="og:site_name"
                content={`Deskar - ${data.title} - Strony internetowe Poznań`}
              />
              <meta
                property="og:image"
                content={"https://deskar.pl" + metaLogo}
              />
              <meta property="og:image:width" content="680" />
              <meta property="og:image:height" content="465" />
              <meta property="og:image:type" content="image/png" />
              <meta name="author" content="Deskar" />
              <meta
                name="google-site-verification"
                content="v2VHB88HtZjc-nBkDZvxkaKRApnVtm-v9Mfdg9wn8Ac"
              />
            </Helmet>
          </HelmetProvider>
          <section className="singleProject">
            <h2>{data.title}</h2>
            <div className="singleProject-top big-heading">
              <p className="category text">{data.category.join(", ")}</p>
              <p className="date text">{data.date}</p>
            </div>
          </section>

          <section className="singleProject-mockups">
            <img
              src={`/photos/${data.mockup1}`}
              alt={`${data.title} - prezentacja pierwszego mockup`}
            />
            <div>
              <img
                src={`/photos/${data.mockup2}`}
                alt={`${data.title} - prezentacja drugiego mockup`}
              />
              <img
                src={`/photos/${data.mockup3}`}
                alt={`${data.title} - prezentacja trzeciego mockup`}
              />
            </div>
          </section>

          <section className="singleProject-description">
            <p className="category big-text">{data.category.join(", ")}</p>
            <div className="singleProject-description-bottom">
              <div className="left">
                <div className="services">
                  <p className="title small-text">Usługi</p>
                  {data.services &&
                    data.services.map((service) => (
                      <p className="small-text" key={service}>
                        {service}
                      </p>
                    ))}
                </div>
              </div>
              <div className="right">
                <p className="big-text">{data.description}</p>
                <hr></hr>
                {data.category.includes("Strony internetowe") && (
                  <div>
                    <svg
                      width="24"
                      height="24"
                      xmlns="http://w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    >
                      <path d="M15.246 17c-.927 3.701-2.547 6-3.246 7-.699-1-2.32-3.298-3.246-7h6.492zm7.664 0c-1.558 3.391-4.65 5.933-8.386 6.733 1.315-2.068 2.242-4.362 2.777-6.733h5.609zm-21.82 0h5.609c.539 2.386 1.47 4.678 2.777 6.733-3.736-.8-6.828-3.342-8.386-6.733zm14.55-2h-7.28c-.29-1.985-.29-4.014 0-6h7.281c.288 1.986.288 4.015-.001 6zm-9.299 0h-5.962c-.248-.958-.379-1.964-.379-3s.131-2.041.379-3h5.962c-.263 1.988-.263 4.012 0 6zm17.28 0h-5.963c.265-1.988.265-4.012.001-6h5.962c.247.959.379 1.964.379 3s-.132 2.042-.379 3zm-8.375-8h-6.492c.925-3.702 2.546-6 3.246-7 1.194 1.708 2.444 3.799 3.246 7zm-8.548-.001h-5.609c1.559-3.39 4.651-5.932 8.387-6.733-1.237 1.94-2.214 4.237-2.778 6.733zm16.212 0h-5.609c-.557-2.462-1.513-4.75-2.778-6.733 3.736.801 6.829 3.343 8.387 6.733z" />
                    </svg>

                    <a
                      className="small-text"
                      href={data.website}
                      target="_blank"
                    >
                      Website
                    </a>
                  </div>
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default SingleProject;
