import React, { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

function Work() {
  const work = useRef();

  useGSAP(
    () => {
      gsap.registerPlugin(ScrollTrigger);

      const sections = document.querySelectorAll(".container section");

      const mask = document.querySelector(".mask");

      const workcon = document.querySelector(".work");

      let scrollTween = gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: work.current,
          start: "top top",
          pin: true,
          scrub: 1,
          end: "+=4500",
        },
      });

      gsap.to(mask, {
        width: "103%",
        scrollTrigger: {
          trigger: workcon,
          start: "top left",
          end: "+=5600",
          markers: false,
          scrub: 1,
        },
      });

      sections.forEach((section) => {
        let text = section.querySelectorAll(".anim");

        if (text.length === 0) return;

        gsap.from(text, {
          x: 130,
          opacity: 0,
          duration: 1,
          ease: "sine.out",
          stagger: 0.1,
          scrollTrigger: {
            trigger: section,
            containerAnimation: scrollTween,
            start: "left center",
          },
        });
      });
    },
    { scope: work }
  );

  return (
    <div className="workSection">
      <div className="work" ref={work}>
        <h3 className="text">ZOBACZ JAK DZIAŁAMY</h3>
        <svg
          viewBox="0 0 900 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="svg"
        >
          <path fill="#2D2D2D" d="M.214.322h1321.68v3.235H.214z" />

          <mask
            id="mask0_0_1"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="900"
            height="10"
          >
            <path fill="#2D2D2D" d="M.214.322h1321.68v3.235H.214z" />
          </mask>
          <g mask="url(#mask0_0_1)">
            <rect className="mask" y="-49" height="99" fill="#FF4D4D" />
          </g>
        </svg>

        <div className="container scrollx">
          <section className="sec1 pin">
            <div className="des">
              <p className="text">01 /</p>
              <h1 className="heading">Rozmowa</h1>
              <div className="col">
                <p className="text">
                  Przejdziemy teraz przez szczegółowy brief, który pomoże nam
                  zebrać kluczowe informacje dotyczące Twojej firmy oraz
                  określić wstępny zakres pracy. Ten etap jest niezbędny do
                  opracowania projektu, który będzie idealnie dopasowany do
                  Twoich potrzeb i celów biznesowych. Innymi słowy, dzięki temu
                  procesowi będziemy w stanie lepiej zrozumieć, jakie są Twoje
                  oczekiwania i dostosować nasze działania, aby osiągnąć
                  optymalne rezultaty dla Twojej firmy.
                </p>
              </div>
            </div>
          </section>
          <section className="sec2 pin">
            <div className="des ">
              <p className="text">02 /</p>
              <h1 className="heading">Analiza</h1>

              <div className="col">
                <p className="text">
                  Po uzyskaniu wstępnego zatwierdzenia projektu, skupię się
                  teraz na zebraniu informacji dotyczących rynku oraz Twojej
                  branży. Po dokładnej ocenie, podzielę się z Tobą moimi
                  spostrzeżeniami, omówimy dodatkowe szczegóły i dostosujemy
                  projekt, aby jak najlepiej odpowiadał Twoim potrzebom. Ten
                  etap pozwoli nam jeszcze lepiej dostosować strategię do
                  specyfiki Twojej profesji i rynku, co przyczyni się do
                  osiągnięcia optymalnych rezultatów.
                </p>
              </div>
            </div>
          </section>
          <section className="sec3 pin">
            <div className="des anim">
              <p className="text">03 /</p>
              <h1 className="heading">Layout</h1>

              <div className="col ">
                <p className="text">
                  Z posiadanych informacji i uwzględniając Twoje oczekiwania,
                  przystępuję do przygotowania szablonu strony, który otrzymasz
                  drogą mailową. Na tym etapie będziemy dokładnie dostosowywać
                  projekt, wprowadzając niezbędne poprawki, aby osiągnąć
                  ostateczną wersję zgodną z Twoimi oczekiwaniami. Wspólnie
                  przejdziemy przez wszelkie modyfikacje i doprecyzujemy
                  szczegóły, aby upewnić się, że finalny projekt spełnia
                  wszystkie Twoje wymagania.
                </p>
              </div>
            </div>
          </section>
          <section className="sec4 pin">
            <div className="des anim">
              <p className="text">04 /</p>
              <h1 className="heading">Development</h1>

              <div className="col ">
                <p className="text">
                  Na bazie przygotowanej makiety rozpoczynam proces budowy
                  Twojej strony internetowej, kreując ją od podstaw.
                  Wykorzystuję moją wiedzę i umiejętności, aby dostarczyć Ci
                  produkt najwyższej jakości. Po zakończeniu budowy
                  przeprowadzam dokładne testy strony na różnych przeglądarkach
                  i urządzeniach, eliminując wszelkie ewentualne błędy. Celem
                  jest zapewnienie, że Twoja strona działa sprawnie i prezentuje
                  się atrakcyjnie na różnych platformach, dostarczając odbiorcom
                  doskonałe wrażenia.
                </p>
              </div>
            </div>
          </section>
          <section className="sec5 pin">
            <div className="des  anim">
              <p className="text">05 /</p>
              <h1 className="heading">Finał</h1>

              <div className="col ">
                <p className="text">
                  Po ukończeniu strony, wrzucam ją na hosting i przekazuję Ci
                  wszystkie niezbędne dostępy oraz instrukcje dotyczące
                  zarządzania Twoją stroną. Dodatkowo, jestem dostępny do
                  udzielenia pomocy i wsparcia w przypadku pytań lub innych
                  potrzeb związanych z Twoją stroną internetową. Chcę zapewnić,
                  że masz pełną kontrolę nad swoim projektem i że jestem tutaj,
                  aby służyć pomocą w każdym etapie.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Work;
