import React, { useState, useEffect } from "react";
import anime from "animejs/lib/anime.es.js";

import UseFetchProjects from "./UseFetch/UseFetchProjects";
import { Link } from "react-scroll";

const Header = () => {
  const { data, error } = UseFetchProjects(
    "https://db-json-gamma.vercel.app/project"
  );
  const [menuVisible, setMenuVisible] = useState(false);
  const [line, setLine] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [scrolled, setScrolled] = useState(false);
  const [lengthProject, setlengthProject] = useState(0);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
    setLine(!line);
    anime({
      targets: ".menu ul a",
      translateX: !menuVisible ? [700, 0] : [0, 0],
      duration: 1000,
      delay: function (el, i, l) {
        return i * 100;
      },
      endDelay: function (el, i, l) {
        return (l - i) * 100;
      },
      easing: "easeInOutExpo",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    anime({
      targets: ".main-nav",
      translateY: [-700, 0],
      duration: 1000,
      opacity: 1,
      easing: "easeInOutExpo",
    });
  }, []);

  useEffect(() => {
    if (scrolled) {
      document.querySelector(".main-nav").classList.add("scrolled");
    } else {
      document.querySelector(".main-nav").classList.remove("scrolled");
    }
  }, [scrolled]);

  useEffect(() => {
    const timerID = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  useEffect(() => {
    if (data) {
      setlengthProject(data.length);
    }
  }, [data]);

  return (
    <nav className={`main-nav ${menuVisible ? "visible" : ""}`}>
      <div className="logo">
        <a href="/" aria-label="logo">
          <img src="/photos/logo.png" alt="Deskar"></img>
        </a>
      </div>
      <div className={`menu ${menuVisible ? "visible" : ""}`}>
        <ul className="small-text ">
          <li>
            <a href="https://www.deskar.pl" aria-label="Start Deskar">
              Start
            </a>
          </li>
          <li>
            <a href="/oferta" aria-label="Oferta">
              Oferta
            </a>
          </li>
          <li>
            <a href="/portfolio" aria-label="Portfolio">
              Portfolio <sup>{lengthProject}</sup>
            </a>
          </li>
          <li>
            <a href="/o-nas" aria-label="O nas">
              O nas
            </a>
          </li>
          <li>
            <Link
              href="#contacts"
              to="contacts"
              aria-label="Kontakt"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
            >
              Kontakt
            </Link>
          </li>
        </ul>
      </div>
      <div className="time">
        <p className="small-text">Poznań (GMT + 01:00)</p>
        <p className="clock">
          {currentTime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </p>
      </div>
      <div className="hamburger">
        <button
          className="hamburger-button"
          aria-label="Menu"
          onClick={toggleMenu}
        >
          <div className={`line ${line ? "visible" : ""}`}></div>
          <div className={`line ${line ? "visible" : ""}`}></div>
        </button>
      </div>
    </nav>
  );
};

export default Header;
