import React, { useEffect, useState, useRef } from "react";
import anime from "animejs/lib/anime.es.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useParams } from "react-router-dom";
import UseFetchProjects from "../UseFetch/UseFetchProjects";
import { useGSAP } from "@gsap/react";

const Description = () => {
  const { city } = useParams();
  const { data, error } = UseFetchProjects(
    "https://db-json-gamma.vercel.app/offer"
  );
  const [filteredData, setFilteredData] = useState(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (data) {
      const currentPath = window.location.pathname;
      let filteredProject;

      if (
        currentPath.includes("tworzenie-stron-internetowych") ||
        currentPath.includes("strony-internetowe")
      ) {
        filteredProject = data.find(
          (project) => project.id === "strony-internetowe"
        );
      } else if (currentPath.includes("projektowanie-graficzne")) {
        filteredProject = data.find(
          (project) => project.id === "projektowanie-graficzne"
        );
      } else if (currentPath.includes("tworzenie-logo")) {
        filteredProject = data.find(
          (project) => project.id === "tworzenie-logo"
        );
      } else if (currentPath.includes("tworzenie-animacji")) {
        filteredProject = data.find(
          (project) => project.id === "tworzenie-animacji"
        );
      } else if (currentPath.includes("pozycjonowanie-stron")) {
        filteredProject = data.find(
          (project) => project.id === "pozycjonowanie-stron"
        );
      } else if (currentPath.includes("marketing-w-social-media")) {
        filteredProject = data.find(
          (project) => project.id === "marketing-w-social-media"
        );
      }

      setFilteredData(filteredProject);
    }
  }, [data]);

  useGSAP(() => {
    if (filteredData) {
      gsap.registerPlugin(ScrollTrigger);

      ScrollTrigger.create({
        trigger: descriptionRef.current,
        start: "top 95%",
        markers: false,
        scrub: 3,
        once: true,
        onEnter: () => {
          anime({
            targets:
              ".description-website-title, .description-website-content-left",
            translateX: [-1200, 0],
            opacity: 1,
            duration: 1600,
            easing: "easeInOutExpo",
          });
          anime({
            targets: ".description-photo",
            translateY: [1000, -50],
            opacity: 1,
            duration: 1600,
            easing: "easeInOutExpo",
          });
        },
      });
    }
  }, [filteredData]);

  const formatCityName = (city) => {
    switch (city) {
      case "strony-internetowe-poznan":
      case "projektowanie-graficzne-poznan":
        return "Poznań";
      case "strony-internetowe-kalisz":
      case "projektowanie-graficzne-kalisz":
        return "Kalisz";
      case "strony-internetowe-ostrow-wielkopolski":
      case "projektowanie-graficzne-ostrow-wielkopolski":
        return "Ostrów Wielkopolski";
      case "strony-internetowe-gniezno":
      case "projektowanie-graficzne-gniezno":
        return "Gniezno";
      case "strony-internetowe-leszno":
      case "projektowanie-graficzne-leszno":
        return "Leszno";
      case "strony-internetowe-konin":
      case "projektowanie-graficzne-konin":
        return "Konin";
      case "strony-internetowe-lubon":
      case "projektowanie-graficzne-lubon":
        return "Luboń";
      case "strony-internetowe-wrzesnia":
      case "projektowanie-graficzne-wrzesnia":
        return "Września";
      case "strony-internetowe-swarzedz":
      case "projektowanie-graficzne-swarzedz":
        return "Swarzędz";
      case "strony-internetowe-krotoszyn":
      case "projektowanie-graficzne-krotoszyn":
        return "Krotoszyn";
      case "strony-internetowe-jarocin":
      case "projektowanie-graficzne-jarocin":
        return "Jarocin";
      default:
        return "Poznań";
    }
  };

  const renderHTML = (htmlString) => {
    return { __html: htmlString };
  };

  return (
    <>
      {filteredData && (
        <section
          id="description"
          className="description-website"
          key={filteredData.id}
          ref={descriptionRef}
        >
          <div className="description-website-content">
            <div className="description-website-content-left">
              <h2 className="description-website-title">
                {filteredData.descriptionWebsiteTitle} {formatCityName(city)}
              </h2>
              <h2>{filteredData.descriptionWebsiteSubTitle}</h2>
              <div
                dangerouslySetInnerHTML={renderHTML(
                  filteredData.descriptionWebsiteSubTitleDescription
                )}
              />
              <h2>{filteredData.descriptionWebsiteSubTitle2}</h2>
              <div
                dangerouslySetInnerHTML={renderHTML(
                  filteredData.descriptionWebsiteSubTitleDescription2
                )}
              />
            </div>
            <div
              className={`description-photo ${filteredData.descriptionWebsitePhoto}`}
            ></div>
          </div>
        </section>
      )}
    </>
  );
};

export default Description;
