import React, { useState, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import anime from "animejs/lib/anime.es.js";
import BgAnimCounter from "../Background/BgAnimCounter";
gsap.registerPlugin(useGSAP, ScrollTrigger);

const Counter = ({ idCount, awCount, expCount }) => {
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: ".counter",
      start: "center 90%",
      markers: false,
      scrub: 3,
      once: true,
      onEnter: () => {
        // Animacja dla expCount i idCount
        anime({
          targets: ".id .number",
          innerHTML: [0, 100], // Animacja od 0 do 20000
          round: 1, // Zaokrąglanie wartości do liczby całkowitej
          easing: "linear", // Wybierz funkcję łatwości animacji
          duration: 2500, // Czas trwania animacji w milisekundach
        });

        anime({
          targets: ".aw .number",
          innerHTML: [0, 22], // Animacja od 0 do 35
          round: 1, // Zaokrąglanie wartości do liczby całkowitej
          easing: "linear", // Wybierz funkcję łatwości animacji
          duration: 2500, // Czas trwania animacji w milisekundach
        });
        anime({
          targets: ".exp .number",
          innerHTML: [0, 6], // Animacja od 0 do 35
          round: 1, // Zaokrąglanie wartości do liczby całkowitej
          easing: "linear", // Wybierz funkcję łatwości animacji
          duration: 2500, // Czas trwania animacji w milisekundach
        });
      },
    });
  }, []);
  return (
    <section className="counter">
      <div className="id">
        <p className="number big-heading">{idCount}</p>
        <p className="plus big-heading">k+</p>
        <div className="counter-des">
          <p className="text">Napisanych linijek kodu</p>
          <hr></hr>
        </div>
      </div>
      <div className="aw">
        <div className="counter-des">
          <p className="text">Stworzone projekty</p>
          <hr></hr>
        </div>
        <p className="number big-heading">{awCount}</p>
      </div>
      <div className="exp">
        <p className="number big-heading">{expCount}</p>
        <div className="counter-des">
          <p className="text">Lat doświadczenia</p>
          <hr></hr>
        </div>
      </div>
      <BgAnimCounter />
    </section>
  );
};

export default Counter;
