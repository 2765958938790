import React, { useEffect } from "react";

const Mouse = () => {
  useEffect(() => {
    const cursor = document.querySelector(".cursor");

    const handleMouseMove = (e) => {
      const posX = e.clientX;
      const posY = e.clientY;
      cursor.style.left = `${posX}px`;
      cursor.style.top = `${posY}px`;
    };

    const handleMouseEnter = () => {
      cursor.style.transform = "translate(-50%, -50%) scale(1.4)";
    };

    const handleMouseLeave = () => {
      cursor.style.transform = "translate(-50%, -50%) scale(1)";
    };

    const handleHelpInfoHover = () => {
      cursor.style.transform = "translate(-50%, -50%) scale(1.4)";
      cursor.style.backgroundColor = "white";
      cursor.style.color = "black";
      cursor.style.fontSize = "12px";
      cursor.textContent = "Show more";
    };

    const handleHelpInfoLeave = () => {
      cursor.style.transform = "translate(-50%, -50%) scale(1)";
      cursor.style.backgroundColor = "";
      cursor.style.color = "";
      cursor.textContent = "";
    };

    const assignEventListeners = () => {
      const projectGalleries = document.querySelectorAll(".project-gallery");
      projectGalleries.forEach((gallery) => {
        gallery.addEventListener("mouseenter", handleHelpInfoHover);
        gallery.addEventListener("mouseleave", handleHelpInfoLeave);
      });

      const interactiveElements = document.querySelectorAll(
        "button, a, input, li, .question"
      );
      interactiveElements.forEach((element) => {
        element.addEventListener("mouseenter", handleMouseEnter);
        element.addEventListener("mouseleave", handleMouseLeave);
      });

      const helpInfo = document.querySelector(".help-info");
      if (helpInfo) {
        helpInfo.addEventListener("mouseenter", handleHelpInfoHover);
        helpInfo.addEventListener("mouseleave", handleHelpInfoLeave);
      }
    };

    const removeEventListeners = () => {
      const projectGalleries = document.querySelectorAll(".project-gallery");
      projectGalleries.forEach((gallery) => {
        gallery.removeEventListener("mouseenter", handleHelpInfoHover);
        gallery.removeEventListener("mouseleave", handleHelpInfoLeave);
      });

      const interactiveElements = document.querySelectorAll(
        "button, a, input, li, .question"
      );
      interactiveElements.forEach((element) => {
        element.removeEventListener("mouseenter", handleMouseEnter);
        element.removeEventListener("mouseleave", handleMouseLeave);
      });

      const helpInfo = document.querySelector(".help-info");
      if (helpInfo) {
        helpInfo.removeEventListener("mouseenter", handleHelpInfoHover);
        helpInfo.removeEventListener("mouseleave", handleHelpInfoLeave);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    assignEventListeners();

    const observer = new MutationObserver(() => {
      removeEventListeners();
      assignEventListeners();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      removeEventListeners();
      observer.disconnect();
    };
  }, []);

  return <div className="cursor changeIndex"></div>;
};

export default Mouse;
