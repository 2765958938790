import React, { useEffect } from "react";
import anime from "animejs/lib/anime.es.js";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import metaLogo from "../assets/metalogo.png";
const MainBanner = () => {
  const { city } = useParams();

  useEffect(() => {
    anime({
      targets: ".main-banner-left",
      translateY: [1000, 0],
      duration: 2000,
      delay: 300,
      easing: "easeInOutExpo",
    });
    anime({
      targets: ".main-banner-right",
      translateX: [1000, 0],
      duration: 2000,
      delay: 300,
      easing: "easeInOutExpo",
    });
    anime({
      targets: ".head-line hr",
      width: [0, 1000],
      delay: 300,
      easing: "easeInOutExpo",
      duration: 2000,
    });
    anime({
      targets: ".main-banner-right h2",
      translateX: [1000, 0],
      duration: 2000,
      delay: anime.stagger(80),
      easing: "easeInOutExpo",
    });
    anime({
      targets: ".cube",
      keyframes: [
        { scale: 0.8, rotate: 45, duration: 2000 },
        { scale: 1, rotate: 0, duration: 2000, delay: 1000 },
        { scale: 1, rotate: 0, duration: 2000 },
      ],
      easing: "easeOutElastic",
      loop: true,
    });
    anime({
      targets: ".text-anim p",
      keyframes: [
        { translateY: "-100%", duration: 2000 },
        { translateY: 0, duration: 2000, delay: 1000 },
        { translateY: 0, duration: 2000 },
      ],
      easing: "easeOutElastic",
      loop: true,
    });
  }, []);

  return (
    <>
      <section className="main-banner">
        <div className="main-banner-left">
          <p className="big-heading ">You can only </p>
          <p className="big-heading ">go as far</p>
          <div className="anim-h1">
            <div className="cube"></div>
            <div className="text-anim">
              <p className="big-heading ">as you push.</p>
              <p className="big-heading ">as you can.</p>
            </div>
          </div>
        </div>
        <div className="main-banner-right">
          <div className="head-line">
            <hr></hr>
            <h4 className="big-text ">Since 2023</h4>
          </div>
          <h1 className="small-heading">Strony internetowe - Poznań</h1>
          <h2 className="small-heading">Identyfikacje wizualne</h2>
          <h2 className="small-heading">Projektowanie logo</h2>
          <h2 className="small-heading">Customowe kody</h2>
          <h2 className="small-heading">Animacje</h2>
          <h2 className="small-heading">& więcej</h2>
        </div>
      </section>
    </>
  );
};

export default MainBanner;
