import React, { useEffect, useState, useRef } from "react";
import anime from "animejs/lib/anime.es.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import Bglinesingle from "../Background/Bglinesingle";
import { Link } from "react-scroll";
import { useParams } from "react-router-dom";
import UseFetchProjects from "../UseFetch/UseFetchProjects";

const Price = () => {
  const { city } = useParams();
  const { data, error } = UseFetchProjects(
    "https://db-json-gamma.vercel.app/offer"
  );
  const [filteredData, setFilteredData] = useState(null);
  const priceRef = useRef(null);

  useEffect(() => {
    if (data) {
      const currentPath = window.location.pathname;
      let filteredProject;

      if (
        currentPath.includes("tworzenie-stron-internetowych") ||
        currentPath.includes("strony-internetowe")
      ) {
        filteredProject = data.find(
          (project) => project.id === "strony-internetowe"
        );
      } else if (currentPath.includes("projektowanie-graficzne")) {
        filteredProject = data.find(
          (project) => project.id === "projektowanie-graficzne"
        );
      } else if (currentPath.includes("tworzenie-logo")) {
        filteredProject = data.find(
          (project) => project.id === "tworzenie-logo"
        );
      } else if (currentPath.includes("tworzenie-animacji")) {
        filteredProject = data.find(
          (project) => project.id === "tworzenie-animacji"
        );
      } else if (currentPath.includes("pozycjonowanie-stron")) {
        filteredProject = data.find(
          (project) => project.id === "pozycjonowanie-stron"
        );
      } else if (currentPath.includes("marketing-w-social-media")) {
        filteredProject = data.find(
          (project) => project.id === "marketing-w-social-media"
        );
      }

      setFilteredData(filteredProject);
    }
  }, [data]);

  useGSAP(() => {
    if (filteredData) {
      gsap.registerPlugin(ScrollTrigger);

      ScrollTrigger.create({
        trigger: priceRef.current,
        start: "top 40%",
        markers: false,
        scrub: 3,
        once: true,
        onEnter: () => {
          anime({
            targets: ".price-website .price-website-title",
            translateY: [200, 0],
            opacity: 1,
            duration: 1000,
            easing: "easeInOutExpo",
          });

          anime({
            targets: ".price-website-layout  .gsap",
            translateY: [200, 0],
            opacity: 1,
            duration: 2000,
            delay: function (el, i, l) {
              return i * 200;
            },
            endDelay: function (el, i, l) {
              return (l - i) * 200;
            },
            easing: "easeInOutExpo",
          });
        },
      });
    }
  }, [filteredData]);

  const formatCityName = (city) => {
    switch (city) {
      case "strony-internetowe-poznan":
      case "projektowanie-graficzne-poznan":
        return "Poznań";
      case "strony-internetowe-kalisz":
      case "projektowanie-graficzne-kalisz":
        return "Kalisz";
      case "strony-internetowe-ostrow-wielkopolski":
      case "projektowanie-graficzne-ostrow-wielkopolski":
        return "Ostrów Wielkopolski";
      case "strony-internetowe-gniezno":
      case "projektowanie-graficzne-gniezno":
        return "Gniezno";
      case "strony-internetowe-leszno":
      case "projektowanie-graficzne-leszno":
        return "Leszno";
      case "strony-internetowe-konin":
      case "projektowanie-graficzne-konin":
        return "Konin";
      case "strony-internetowe-lubon":
      case "projektowanie-graficzne-lubon":
        return "Luboń";
      case "strony-internetowe-wrzesnia":
      case "projektowanie-graficzne-wrzesnia":
        return "Września";
      case "strony-internetowe-swarzedz":
      case "projektowanie-graficzne-swarzedz":
        return "Swarzędz";
      case "strony-internetowe-krotoszyn":
      case "projektowanie-graficzne-krotoszyn":
        return "Krotoszyn";
      case "strony-internetowe-jarocin":
      case "projektowanie-graficzne-jarocin":
        return "Jarocin";
      default:
        return "Poznań";
    }
  };

  const renderHTML = (htmlString) => {
    return { __html: htmlString };
  };

  return (
    <>
      {filteredData && (
        <section className="price-website">
          <Bglinesingle />
          <h4
            className="price-website-title"
            key={filteredData.id}
            ref={priceRef}
          >
            {filteredData.priceTitle}{" "}
            {filteredData.firstPriceTitle && formatCityName(city)}
          </h4>
          <div className="price-website-layout">
            {filteredData.firstPriceTitle &&
              filteredData.firstPriceDescription &&
              filteredData.firstPriceInfo && (
                <div className="landing-page gsap">
                  <h4> {filteredData.firstPriceTitle}</h4>
                  <div
                    dangerouslySetInnerHTML={renderHTML(
                      filteredData.firstPriceDescription
                    )}
                  />
                  <div
                    dangerouslySetInnerHTML={renderHTML(
                      filteredData.firstPriceInfo
                    )}
                  />
                  <div className="more-button">
                    <Link
                      href="#contacts"
                      to="contacts"
                      aria-label="Kontakt"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}
                    >
                      ZAMÓW OFERTĘ
                    </Link>
                  </div>
                </div>
              )}
            {filteredData.secPriceTitle &&
              filteredData.secPriceDescription &&
              filteredData.secPriceInfo && (
                <div className="company-web gsap">
                  <h4>{filteredData.secPriceTitle}</h4>
                  <div
                    dangerouslySetInnerHTML={renderHTML(
                      filteredData.secPriceDescription
                    )}
                  />
                  <div
                    dangerouslySetInnerHTML={renderHTML(
                      filteredData.secPriceInfo
                    )}
                  />

                  <div className="more-button">
                    <Link
                      href="#contacts"
                      to="contacts"
                      aria-label="Kontakt"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}
                    >
                      ZAMÓW OFERTĘ
                    </Link>
                  </div>
                </div>
              )}
            {filteredData.thPriceTitle &&
              filteredData.thPriceDescription &&
              filteredData.thPriceInfo && (
                <div className="e-commerce-shop gsap">
                  <h4>{filteredData.thPriceTitle}</h4>
                  <div
                    dangerouslySetInnerHTML={renderHTML(
                      filteredData.thPriceDescription
                    )}
                  />
                  <div
                    dangerouslySetInnerHTML={renderHTML(
                      filteredData.thPriceInfo
                    )}
                  />
                  <div className="more-button">
                    <Link
                      href="#contacts"
                      to="contacts"
                      aria-label="Kontakt"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}
                    >
                      ZAMÓW OFERTĘ
                    </Link>
                  </div>
                </div>
              )}
          </div>
        </section>
      )}
    </>
  );
};

export default Price;
