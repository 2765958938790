const Bglinesingle = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1804.07 969.73"
      className="bglinesingle"
    >
      <defs></defs>
      <path
        className="cls-1"
        d="M1091.37,671.05c-37.73-61.41-96.28-109.81-138.74-137.92C907.75,504.52,879,496.2,845,503.49c-33.17,8-63.78,32.25-106.25,59.93-40.56,27.92-93,59.25-125.68,86.51-32.52,28.06-25.17,43.67,19.08,48.74,43.27,6.54,123.43,2.53,213.79,25.22,89.53,19.6,171.17,60.52,219.54,55.73C1114.06,778.89,1129.37,732.46,1091.37,671.05Z"
        transform="translate(30.74 -118.11)"
      />
      <path
        className="cls-1"
        d="M1158.92,675.32c-48.09-78.27-122.71-140-176.82-175.78-57.2-36.46-93.88-47.07-137.18-37.77-42.28,10.16-81.28,41.1-135.41,76.38C657.81,573.73,591,613.65,549.34,648.4c-41.46,35.76-32.09,55.66,24.31,62.12,55.14,8.33,157.31,3.23,272.47,32.14,57,11.47,111.61,32.1,159.58,47.37,48,15.58,89.34,25.8,120.22,23.88,30.9-1.59,51.33-15.33,57.89-40.21C1190.38,749.14,1183.08,713.43,1158.92,675.32Z"
        transform="translate(30.74 -118.11)"
      />
      <path
        className="cls-1"
        d="M1226.47,679.59C1168,584.47,1077.33,509.5,1011.57,466c-69.51-44.31-114.1-57.2-166.72-45.9C793.46,432.4,746.06,470,680.27,512.88c-62.82,43.24-144,91.76-194.66,134-50.38,43.46-39,67.64,29.55,75.5,67,10.13,191.19,3.92,331.15,39.06,69.27,13.94,135.64,39,193.94,57.57,58.33,18.94,108.58,31.36,146.12,29,37.55-1.94,62.38-18.63,70.35-48.88C1264.71,769.3,1255.83,725.9,1226.47,679.59Z"
        transform="translate(30.74 -118.11)"
      />
      <path
        className="cls-1"
        d="M1294,683.86c-31.76-54.75-78.23-105.9-123.51-148.6-45.77-42.89-90.35-77.32-129.62-103-79.59-50.82-136.32-68.55-196.11-53.95C784.29,392.86,728.49,437.12,651,487.61c-74,50.9-169.56,108-229.15,157.74C362.58,696.5,376,725,456.67,734.22c78.89,11.92,225.06,4.62,389.83,46,165.18,37.48,310.22,108.66,400.31,101.95,44.54-2,74.09-21.35,82.9-57.47C1338.73,789.2,1327,737,1294,683.86Z"
        transform="translate(30.74 -118.11)"
      />
      <path
        className="cls-1"
        d="M1361.57,688.13c-36.54-63-90-121.85-142.1-171-52.66-49.34-104-89-149.13-118.48-91.57-58.47-156.84-78.87-225.63-62.08-69.6,16.73-133.8,67.65-222.9,125.74C536.72,520.9,426.73,586.62,358.16,643.82c-68.24,58.85-52.81,91.61,40,102.25,90.77,13.71,258.94,5.31,448.5,52.9,95.64,20.51,184.06,53.15,262.59,77.89,78.34,25.06,146.78,42.23,198,39.4,51.23-2.29,85.24-24.56,95.37-66.12C1413,809.32,1399.52,749.21,1361.57,688.13Z"
        transform="translate(30.74 -118.11)"
      />
      <path
        className="cls-1"
        d="M1429.12,692.4c-41.32-71.24-101.78-137.79-160.69-193.34-59.55-55.8-117.55-100.59-168.64-134C996.24,299,922.42,275.9,844.63,294.88c-78.7,18.92-151.29,76.5-252,142.19C496.35,503.29,372,577.61,294.44,642.29c-77.17,66.55-59.73,103.6,45.25,115.63,102.64,15.51,292.82,6,507.18,59.83C955,840.93,1055,877.85,1143.81,905.82c88.59,28.34,166,47.75,223.89,44.56,57.94-2.59,96.39-27.78,107.85-74.77C1487.29,829.44,1472,761.47,1429.12,692.4Z"
        transform="translate(30.74 -118.11)"
      />
      <path
        className="cls-1"
        d="M1496.67,696.67c-46.1-79.48-113.56-153.73-179.29-215.71-66.44-62.25-131.14-112.23-188.15-149.48-115.52-73.77-197.88-99.5-284.67-78.32-44.9,10.44-85.78,31.93-131.18,59.78-45.09,27.88-94.71,62.11-149.81,98.89C508.78,448.64,448.52,488,389.4,527,330.6,566,273,604.56,230.71,640.76c-86.09,74.26-66.63,115.58,50.49,129,114.52,17.31,326.7,6.71,565.86,66.76,120.66,25.87,232.22,67.05,331.29,98.26,98.84,31.62,185.19,53.28,249.8,49.71,64.64-2.89,107.54-31,120.32-83.42C1561.57,849.57,1544.55,773.73,1496.67,696.67Z"
        transform="translate(30.74 -118.11)"
      />
      <path
        className="cls-1"
        d="M1564.22,700.93c-50.88-87.71-125.33-169.66-197.88-238.07-73.33-68.7-144.74-123.87-207.66-165-127.5-81.42-218.4-109.82-314.19-86.44-49.56,11.52-94.68,35.24-144.79,66C649.94,308.19,595.17,346,534.36,386.56c-60.47,40.62-127,84.05-192.23,127.06-64.89,43-128.51,85.66-175.14,125.61-95,82-73.55,127.57,55.72,142.38,126.39,19.1,360.57,7.4,624.54,73.68,133.17,28.55,256.29,74,365.64,108.45,109.09,34.9,204.4,58.8,275.7,54.87,71.35-3.19,118.7-34.21,132.8-92.07C1635.85,869.69,1617.07,786,1564.22,700.93Z"
        transform="translate(30.74 -118.11)"
      />
      <path
        className="cls-1"
        d="M1631.77,705.2c-55.66-96-137.11-185.6-216.47-260.44-80.22-75.16-158.34-135.5-227.17-180.48-139.48-89.07-238.92-120.14-343.71-94.56C790.2,182.32,740.85,208.27,686,241.89c-54.44,33.67-114.35,75-180.88,119.4-66.15,44.44-138.91,91.95-210.29,139-71,47.08-140.59,93.71-191.6,137.41C52.69,680,31.12,717.42,41.57,743.38c10.16,26.45,52.34,41.48,122.59,50.21,139.25,18.91,393.55,9.88,683.27,80.47,145.69,31.24,280.38,81,400,118.64,119.33,38.18,223.59,64.33,301.6,60,78-3.5,129.84-37.42,145.27-100.73C1710.13,889.82,1689.58,798.26,1631.77,705.2Z"
        transform="translate(30.74 -118.11)"
      />
      <path
        className="cls-1"
        d="M1699.31,709.47c-60.43-104.2-148.88-201.55-235-282.81-87.11-81.61-171.94-147.14-246.68-196C1066.12,134,958.14,100.23,844.35,128,731.14,155.76,621.17,239.82,475.94,336,404.44,384.3,325.74,435.9,247.67,487,170.21,538.07,93.37,588.64,39.54,636.18c-54.92,46-78.34,86.56-67,114.75,11,28.72,56.83,45,133.11,54.52C256.88,826,533,816.18,847.62,892.83c158.19,33.92,304.46,87.91,434.36,128.83,129.58,41.46,242.8,69.85,327.5,65.18,84.75-3.79,141-40.63,157.76-109.37C1784.41,909.94,1762.1,810.52,1699.31,709.47Z"
        transform="translate(30.74 -118.11)"
      />
    </svg>
  );
};

export default Bglinesingle;
