import MainBanner from "./components/MainBanner";
import Header from "./components/Header";
import BgAnimMainBaner from "./components/Background/BgAnimMainBanner";
import MockUpVideo from "./components/MockUpVideo";
import About from "./components/About/About";
import Project from "./components/Project/Project";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Portfolio from "./components/Portfolio";
import AboutUs from "./components/About/About-us";
import Mouse from "./components/Mouse";
import SingleProject from "./components/Project/SingleProject";
import Footer from "./components/Footer";
import Faq from "./components/Faq";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import metaLogo from "./assets/metalogo.png";
import { ConsentForm } from "./components/ConsentForm";
import Offer from "./components/Offer";
import MainBannerWebsite from "./components/Website/MainBannerOffer";
import Description from "./components/Website/Description";
import Price from "./components/Website/Price";
import MoreOffer from "./components/Website/MoreOffer";
import MainBannerOffer from "./components/Website/MainBannerOffer";
import Links from "./components/Website/Links";

function App() {
  const { city } = useParams();

  return (
    <Router>
      <HelmetProvider>
        <ConsentForm />
        <Mouse />
        <Header />
        <main className="App">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>Deskar - Strony internetowe Poznań</title>
                    <meta
                      name="robots"
                      content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
                    ></meta>
                    <meta
                      name="viewport"
                      content="width=device-width, minimum-scale=1.0"
                    />
                    <meta
                      name="description"
                      content="Specjalizujemy się w tworzeniu innowacyjnych stron internetowych w Poznaniu, które nie tylko przyciągają uwagę wizualną, ale również gwarantują efektywność i doskonałe doświadczenia użytkownika."
                    />
                    <meta
                      name="keywords"
                      content="Deskar, strony internetowe, projektowanie stron, tworzenie stron, Karwacki, strony wwww, projektowanie graficzne, loga, projektowanie loga, idetyfikacja wizualna, wizytówki, projektowanie wizytówki"
                    />

                    <link
                      rel="canonical"
                      aria-label="deskar.pl"
                      href="https://deskar.pl"
                    ></link>
                    <meta property="og:locale" content="pl_PL" />
                    <meta property="og:type" content="website" />
                    <meta
                      property="og:title"
                      content="Deskar - Strony internetowe Poznań"
                    />
                    <meta
                      property="og:description"
                      content="Specjalizujemy się w tworzeniu innowacyjnych stron internetowych w Poznaniu, które nie tylko przyciągają uwagę wizualną, ale również gwarantują efektywność i doskonałe doświadczenia użytkownika."
                    />
                    <meta property="og:url" content="https://deskar.pl" />
                    <meta
                      property="og:site_name"
                      content="Deskar - Strony internetowe Poznań"
                    />
                    <meta
                      property="og:image"
                      content={"https://deskar.pl" + metaLogo}
                    />
                    <meta property="og:image:width" content="680" />
                    <meta property="og:image:height" content="465" />
                    <meta property="og:image:type" content="image/png" />
                    <meta name="author" content="Deskar" />
                    <meta
                      name="google-site-verification"
                      content="v2VHB88HtZjc-nBkDZvxkaKRApnVtm-v9Mfdg9wn8Ac"
                    />
                  </Helmet>
                  <BgAnimMainBaner />
                  <MainBanner />
                  <MockUpVideo />

                  <About />
                  <Offer />
                  <Project />
                  <Faq />
                </>
              }
            />

            <Route
              path="/portfolio"
              element={
                <>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>
                      Deskar - Portfolio - Strony internetowe Poznań
                    </title>
                    <meta
                      name="robots"
                      content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
                    ></meta>
                    <meta
                      name="viewport"
                      content="width=device-width, minimum-scale=1.0"
                    />
                    <meta
                      name="description"
                      content="Specjalizujemy się w tworzeniu innowacyjnych stron internetowych w Poznaniu, które nie tylko przyciągają uwagę wizualną, ale również gwarantują efektywność i doskonałe doświadczenia użytkownika."
                    />
                    <meta
                      name="keywords"
                      content="Deskar, strony internetowe, projektowanie stron, tworzenie stron, Karwacki, strony wwww, projektowanie graficzne, loga, projektowanie loga, idetyfikacja wizualna, wizytówki, projektowanie wizytówki"
                    />
                    <link
                      rel="canonical"
                      aria-label="deskar.pl - portfolio"
                      href="https://deskar.pl/portfolio"
                    ></link>
                    <meta property="og:locale" content="pl_PL" />
                    <meta property="og:type" content="website" />
                    <meta
                      property="og:title"
                      content="Deskar - Portfolio - Strony internetowe Poznań"
                    />
                    <meta
                      property="og:description"
                      content="Specjalizujemy się w tworzeniu innowacyjnych stron internetowych w Poznaniu, które nie tylko przyciągają uwagę wizualną, ale również gwarantują efektywność i doskonałe doświadczenia użytkownika."
                    ></meta>
                    <meta
                      property="og:url"
                      content="https://deskar.pl/portfolio"
                    />
                    <meta
                      property="og:site_name"
                      content="Deskar - Portfolio - Strony internetowe Poznań"
                    />
                    <meta
                      property="og:image"
                      content={"https://deskar.pl" + metaLogo}
                    />
                    <meta property="og:image:width" content="680" />
                    <meta property="og:image:height" content="465" />
                    <meta property="og:image:type" content="image/png" />
                    <meta name="author" content="Deskar" />
                    <meta
                      name="google-site-verification"
                      content="v2VHB88HtZjc-nBkDZvxkaKRApnVtm-v9Mfdg9wn8Ac"
                    />
                  </Helmet>
                  <Portfolio />
                </>
              }
            />
            <Route
              path="/o-nas"
              element={
                <>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>Deskar - O nas - Strony internetowe Poznań</title>
                    <meta
                      name="robots"
                      content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
                    ></meta>
                    <meta
                      name="viewport"
                      content="width=device-width, minimum-scale=1.0"
                    />
                    <meta
                      name="description"
                      content="Nasza agencja marketingowa w Poznaniu oferuje kompleksowe usługi związane z tworzeniem i projektowaniem stron internetowych oraz kreowaniem wizerunku marki w internecie."
                    />
                    <meta
                      name="keywords"
                      content="Deskar, strony internetowe, projektowanie stron, tworzenie stron, Karwacki, strony wwww, projektowanie graficzne, loga, projektowanie loga, idetyfikacja wizualna, wizytówki, projektowanie wizytówki"
                    />
                    <link
                      rel="canonical"
                      aria-label="deskar.pl - o nas"
                      href="https://deskar.pl/o-nas"
                    ></link>
                    <meta property="og:locale" content="pl_PL" />
                    <meta property="og:type" content="website" />
                    <meta
                      property="og:title"
                      content="Deskar - O nas - Strony internetowe Poznań"
                    />
                    <meta
                      property="og:description"
                      content="Nasza agencja marketingowa w Poznaniu oferuje kompleksowe usługi związane z tworzeniem i projektowaniem stron internetowych oraz kreowaniem wizerunku marki w internecie."
                    ></meta>
                    <meta property="og:url" content="https://deskar.pl/o-nas" />
                    <meta
                      property="og:site_name"
                      content="Deskar - O nas - Strony internetowe Poznań"
                    />
                    <meta
                      property="og:image"
                      content={"https://deskar.pl" + metaLogo}
                    />
                    <meta property="og:image:width" content="680" />
                    <meta property="og:image:height" content="465" />
                    <meta property="og:image:type" content="image/png" />
                    <meta name="author" content="Deskar" />
                    <meta
                      name="google-site-verification"
                      content="v2VHB88HtZjc-nBkDZvxkaKRApnVtm-v9Mfdg9wn8Ac"
                    />
                  </Helmet>
                  <AboutUs />
                </>
              }
            />
            <Route path="/portfolio/:id" element={<SingleProject />} />
            <Route
              path="/oferta"
              element={
                <>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>Deskar - Oferta - Strony internetowe Poznań</title>
                    <meta
                      name="robots"
                      content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
                    ></meta>
                    <meta
                      name="viewport"
                      content="width=device-width, minimum-scale=1.0"
                    />
                    <meta
                      name="description"
                      content="Specjalizujemy się w tworzeniu innowacyjnych stron internetowych w Poznaniu, które nie tylko przyciągają uwagę wizualną, ale również gwarantują efektywność i doskonałe doświadczenia użytkownika."
                    />
                    <meta
                      name="keywords"
                      content="Deskar, strony internetowe, projektowanie stron, tworzenie stron, Karwacki, strony wwww, projektowanie graficzne, loga, projektowanie loga, idetyfikacja wizualna, wizytówki, projektowanie wizytówki"
                    />

                    <link
                      rel="canonical"
                      aria-label="deskar.pl"
                      href="https://deskar.pl/oferta"
                    ></link>
                    <meta property="og:locale" content="pl_PL" />
                    <meta property="og:type" content="website" />
                    <meta
                      property="og:title"
                      content="Deskar - Oferta - Strony internetowe Poznań"
                    />
                    <meta
                      property="og:description"
                      content="Specjalizujemy się w tworzeniu innowacyjnych stron internetowych w Poznaniu, które nie tylko przyciągają uwagę wizualną, ale również gwarantują efektywność i doskonałe doświadczenia użytkownika."
                    />
                    <meta
                      property="og:url"
                      content="https://deskar.pl/oferta"
                    />
                    <meta
                      property="og:site_name"
                      content="Deskar - Oferta - Strony internetowe Poznań"
                    />
                    <meta
                      property="og:image"
                      content={"https://deskar.pl/oferta" + metaLogo}
                    />
                    <meta property="og:image:width" content="680" />
                    <meta property="og:image:height" content="465" />
                    <meta property="og:image:type" content="image/png" />
                    <meta name="author" content="Deskar" />
                    <meta
                      name="google-site-verification"
                      content="v2VHB88HtZjc-nBkDZvxkaKRApnVtm-v9Mfdg9wn8Ac"
                    />
                  </Helmet>
                  <Offer />
                </>
              }
            />
            <Route
              path="/oferta/:city"
              element={
                <>
                  <MainBannerOffer />
                  <Description />
                  <Price />
                  <Faq />
                  <MoreOffer />
                  <Links />
                </>
              }
            />
          </Routes>
        </main>
        <Footer />
      </HelmetProvider>
    </Router>
  );
}

export default App;
