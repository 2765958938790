import React, { useState } from "react";
import anime from "animejs/lib/anime.es.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const Offer = () => {
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: ".offer ",
      start: "top 40%",
      markers: false,
      scrub: 3,
      once: true,
      onEnter: () => {
        anime({
          targets: ".offer ",
          translateY: [200, 0],
          opacity: 1,
          duration: 2000,
          easing: "easeInOutExpo",
        });

        anime({
          targets: ".offer .help-info a",
          translateY: [200, 0],
          opacity: 1,
          duration: 2000,
          delay: function (el, i, l) {
            return i * 200;
          },
          endDelay: function (el, i, l) {
            return (l - i) * 200;
          },
          easing: "easeInOutExpo",
        });
      },
    });
  }, []);

  return (
    <>
      <section className="offer">
        <div className="heading-small-project">
          <p className="small-text">NASZA OFERTA</p>
          <h2 className="heading">Jak możemy rozwinąć twoją firmę?</h2>
          <hr></hr>
        </div>

        <div className="help-info">
          <a href="/oferta/tworzenie-stron-internetowych">
            <div className="projekty-i-wdrożenia-stron-www">
              <h2 className="small-heading">Tworzenie stron internetowych</h2>
              <p className="small-text">
                Tworzenie stron internetowych to sztuka łączenia kreatywności z
                funkcjonalnością. Nasz zespół specjalistów zajmuje się
                projektowaniem i programowaniem stron dopasowanych do unikalnych
                potrzeb klientów.
              </p>
            </div>
          </a>
          <a href="/oferta/projektowanie-graficzne">
            <div className="projekty-id">
              <h2 className="small-heading">Projektowanie graficzne</h2>
              <p className="small-text">
                Nasza agencja oferuje kreatywne i profesjonalne projektowanie
                graficzne, które skutecznie przekazuje przekaz marki.
                Dopasowujemy nasze projekty do celów biznesowych klienta, dążąc
                do maksymalizacji efektów marketingowych.
              </p>
            </div>
          </a>
          <a href="/oferta/tworzenie-logo">
            <div className="projekty-logo">
              <h2 className="small-heading">Tworzenie loga</h2>
              <p className="small-text">
                Nasz zespół projektuje unikalne loga, które doskonale oddają
                charakter Twojej marki. Dzięki naszej kreatywności i precyzji,
                tworzymy logo, które przyciąga uwagę i wyróżnia się w tłumie,
                pomagając budować rozpoznawalność Twojego biznesu.
              </p>
            </div>
          </a>

          <a href="/oferta/tworzenie-animacji">
            <div className="projekty-aniamcje">
              <h2 className="small-heading">Tworzenie animacji</h2>
              <p className="small-text">
                Nasza firma specjalizuje się w tworzeniu dynamicznych animacji,
                które wzbogacają komunikację marki. Z pasją i zaawansowaną
                technologią, tworzymy animacje, które przyciągają uwagę i
                angażują odbiorców.
              </p>
            </div>
          </a>
          <a href="/oferta/pozycjonowanie-stron">
            <div className="projekty-seo">
              <h2 className="small-heading">Pozycjonowanie stron</h2>
              <p className="small-text">
                Nasza firma oferuje kompleksowe pozycjonowanie stron
                internetowych, które zapewnia widoczność i osiągalność Twojej
                strony w wyszukiwarkach. Dzięki strategiom SEO, pomagamy Twojej
                stronie osiągnąć wyższe wyniki.
              </p>
            </div>
          </a>
          <a href="/oferta/marketing-w-social-media">
            <div className="projekty-social">
              <h2 className="small-heading">Marketing w social media</h2>
              <p className="small-text">
                Nasza agencja specjalizuje się w efektywnym marketingu w mediach
                społecznościowych. Wykorzystujemy strategie dopasowane do Twojej
                marki, aby zaangażować i zainteresować Twoich odbiorców.
              </p>
            </div>
          </a>
        </div>
      </section>
    </>
  );
};

export default Offer;
