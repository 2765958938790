import React, { useEffect } from "react";
import anime from "animejs/lib/anime.es.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import Bglinesingle from "./Background/Bglinesingle";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const Footer = () => {
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    setTimeout(() => {
      ScrollTrigger.create({
        trigger: ".contacts", // Trigger element
        start: "top 50%", // Start point of the trigger
        markers: false, // Whether to display debug markers
        scrub: 3, // Whether animation should scrub or not
        once: true,
        onEnter: () => {
          anime({
            targets: ".contacts",
            translateY: [500, 0],
            opacity: 1,
            duration: 1000,

            easing: "easeInOutExpo",
          });
        },
      });
    }, 2000);
  }, []);
  return (
    <>
      <footer className="contacts" id="contacts">
        <Bglinesingle />
        <div className="heading-small-contacts">
          <h5 className="small-text">KONTAKT</h5>
        </div>
        <div className="title-contacts">
          <h5>COLLABORATE</h5>
        </div>
        <div className="info-contacts">
          <div className="social">
            <h5 className="text">SOCIAL</h5>
            <div>
              <a href="" className="small-text">
                Facebook
              </a>
            </div>
            <div>
              <a href="" className="small-text">
                Instagram
              </a>
            </div>
            <div>
              <a href="" className="small-text">
                Tiktok
              </a>
            </div>
          </div>
          <div className="address">
            <h5 className="text">ADRES</h5>
            <div>
              <p className="small-text">Poland, Poznań</p>
            </div>
            <div>
              <p className="small-text"> 60-149</p>
            </div>
          </div>
          <div className="description-contacts">
            <p className="text">
              Z niecierpliwością oczekujemy na kontakt mailowy lub telefoniczny
              od Ciebie w sprawie potencjalnych współprac, zapytań czy też po
              prostu na przyjazną i kreatywną dyskusję.
            </p>
            <div className="description-contacts-buttons">
              <div>
                <a href="mailto:studio@deskar.pl" className="text">
                  studio@deskar.pl
                </a>
              </div>
              <div>
                <a href="tel:724798688" className="text">
                  724-798-688
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="contacts-footer">
          <div className="social">
            <h5 className="text">SOCIAL</h5>
            <div>
              <a href="" className="small-text">
                Facebook
              </a>
            </div>
            <div>
              <a href="" className="small-text">
                Instagram
              </a>
            </div>
            <div>
              <a href="" className="small-text">
                Tiktok
              </a>
            </div>
          </div>
          <div className="website">
            <h5 className="text">STRONY INTERNETOWE</h5>
            <div>
              <a
                href="/oferta/strony-internetowe-poznan"
                className="small-text"
              >
                Strony internetowe Poznań
              </a>
            </div>
            <div>
              <a
                href="/oferta/strony-internetowe-kalisz"
                className="small-text"
              >
                Strony internetowe Kalisz
              </a>
            </div>
            <div>
              <a
                href="/oferta/strony-internetowe-ostrow-wielkopolski"
                className="small-text"
              >
                Strony internetowe Ostrów Wielkopolski
              </a>
            </div>
          </div>

          <div className="address">
            <h5 className="text">ADRES</h5>
            <p className="small-text">Poland, Poznań</p>
            <p className="small-text"> 60-149</p>
          </div>
          <div className="contact">
            <h5 className="text">KONTAKT</h5>

            <div>
              <a href="mailto:studio@deskar.pl" className="small-text">
                studio@deskar.pl
              </a>
            </div>
            <div>
              <a href="tel:724798688" className="small-text">
                724798688
              </a>
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="made-footer">
          <p className="small-text">Design by deskar</p>
          <p className="small-text">© 2024</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
