import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const Links = () => {
  const { city } = useParams();

  const links = [
    {
      url: "/oferta/strony-internetowe-poznan",
      text: "Strony internetowe Poznań",
    },
    {
      url: "/oferta/strony-internetowe-kalisz",
      text: "Strony internetowe Kalisz",
    },
    {
      url: "/oferta/strony-internetowe-ostrow-wielkopolski",
      text: "Strony internetowe Ostrów Wielkopolski",
    },
    {
      url: "/oferta/strony-internetowe-gniezno",
      text: "Strony internetowe Gniezno",
    },
    {
      url: "/oferta/strony-internetowe-leszno",
      text: "Strony internetowe Leszno",
    },
    {
      url: "/oferta/strony-internetowe-konin",
      text: "Strony internetowe Konin",
    },
    {
      url: "/oferta/strony-internetowe-lubon",
      text: "Strony internetowe Luboń",
    },
    {
      url: "/oferta/strony-internetowe-wrzesnia",
      text: "Strony internetowe Września",
    },
    {
      url: "/oferta/strony-internetowe-swarzedz",
      text: "Strony internetowe Swarzędz",
    },
    {
      url: "/oferta/strony-internetowe-krotoszyn",
      text: "Strony internetowe Krotoszyn",
    },
    {
      url: "/oferta/strony-internetowe-jarocin",
      text: "Strony internetowe Jarocin",
    },
    {
      url: "/oferta/projektowanie-graficzne-poznan",
      text: "Projektowanie graficzne Poznań",
    },
    {
      url: "/oferta/projektowanie-graficzne-kalisz",
      text: "Projektowanie graficzne Kalisz",
    },
    {
      url: "/oferta/projektowanie-graficzne-ostrow-wielkopolski",
      text: "Projektowanie graficzne Ostrów Wielkopolski",
    },
    {
      url: "/oferta/projektowanie-graficzne-gniezno",
      text: "Projektowanie graficzne Gniezno",
    },
    {
      url: "/oferta/projektowanie-graficzne-leszno",
      text: "Projektowanie graficzne Leszno",
    },
    {
      url: "/oferta/projektowanie-graficzne-konin",
      text: "Projektowanie graficzne Konin",
    },
    {
      url: "/oferta/projektowanie-graficzne-lubon",
      text: "Projektowanie graficzne Luboń",
    },
    {
      url: "/oferta/projektowanie-graficzne-wrzesnia",
      text: "Projektowanie graficzne Września",
    },
    {
      url: "/oferta/projektowanie-graficzne-swarzedz",
      text: "Projektowanie graficzne Swarzędz",
    },
    {
      url: "/oferta/projektowanie-graficzne-krotoszyn",
      text: "Projektowanie graficzne Krotoszyn",
    },
    {
      url: "/oferta/projektowanie-graficzne-jarocin",
      text: "Projektowanie graficzne Jarocin",
    },
  ];

  const filteredLinks = links.filter((link) => {
    if (
      city.startsWith("strony-internetowe") ||
      city.startsWith("tworzenie-stron-internetowych")
    ) {
      return (
        link.url.includes("strony-internetowe") ||
        link.url.includes("tworzenie-stron-internetowych")
      );
    } else if (city.startsWith("projektowanie-graficzne")) {
      return link.url.includes("projektowanie-graficzne");
    }
    return false;
  });

  return (
    <section className="links">
      {filteredLinks.map((link) => (
        <div key={link.url}>
          <a href={link.url}>{link.text}</a>
        </div>
      ))}
    </section>
  );
};

export default Links;
