import React, { useEffect, useState } from "react";
import anime from "animejs/lib/anime.es.js";
import { useParams } from "react-router-dom";
import { Link } from "react-scroll";
import { Helmet } from "react-helmet-async";
import metaLogo from "../../assets/metalogo.png";
import UseFetchProjects from "../UseFetch/UseFetchProjects";

const MainBannerOffer = () => {
  const { city } = useParams();
  const { data, error } = UseFetchProjects(
    "https://db-json-gamma.vercel.app/offer"
  );
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    if (data) {
      const currentPath = window.location.pathname;
      let filteredProject;

      if (
        currentPath.includes("tworzenie-stron-internetowych") ||
        currentPath.includes("strony-internetowe")
      ) {
        filteredProject = data.find(
          (project) => project.id === "strony-internetowe"
        );
      } else if (currentPath.includes("projektowanie-graficzne")) {
        filteredProject = data.find(
          (project) => project.id === "projektowanie-graficzne"
        );
      } else if (currentPath.includes("tworzenie-logo")) {
        filteredProject = data.find(
          (project) => project.id === "tworzenie-logo"
        );
      } else if (currentPath.includes("tworzenie-animacji")) {
        filteredProject = data.find(
          (project) => project.id === "tworzenie-animacji"
        );
      } else if (currentPath.includes("pozycjonowanie-stron")) {
        filteredProject = data.find(
          (project) => project.id === "pozycjonowanie-stron"
        );
      } else if (currentPath.includes("marketing-w-social-media")) {
        filteredProject = data.find(
          (project) => project.id === "marketing-w-social-media"
        );
      }

      setFilteredData(filteredProject);
    }
  }, [data]);

  useEffect(() => {
    if (filteredData) {
      anime({
        targets: ".main-banner-bg",
        scale: 0.5,
        opacity: 1,
        duration: 1600,
        easing: "easeInOutExpo",
      });
      anime({
        targets: ".main-banner-website-description",
        translateX: [-1200, 0],
        opacity: 1,
        duration: 2000,
        easing: "easeInOutExpo",
      });
      anime({
        targets: ".main-banner-image",
        translateX: [1000, 0],
        opacity: 1,
        duration: 2000,
        easing: "easeInOutExpo",
      });
    }
  }, [filteredData]);

  const formatCityName = (city) => {
    switch (city) {
      case "strony-internetowe-poznan":
      case "projektowanie-graficzne-poznan":
        return "Poznań";
      case "strony-internetowe-kalisz":
      case "projektowanie-graficzne-kalisz":
        return "Kalisz";
      case "strony-internetowe-ostrow-wielkopolski":
      case "projektowanie-graficzne-ostrow-wielkopolski":
        return "Ostrów Wielkopolski";
      case "strony-internetowe-gniezno":
      case "projektowanie-graficzne-gniezno":
        return "Gniezno";
      case "strony-internetowe-leszno":
      case "projektowanie-graficzne-leszno":
        return "Leszno";
      case "strony-internetowe-konin":
      case "projektowanie-graficzne-konin":
        return "Konin";
      case "strony-internetowe-lubon":
      case "projektowanie-graficzne-lubon":
        return "Luboń";
      case "strony-internetowe-wrzesnia":
      case "projektowanie-graficzne-wrzesnia":
        return "Września";
      case "strony-internetowe-swarzedz":
      case "projektowanie-graficzne-swarzedz":
        return "Swarzędz";
      case "strony-internetowe-krotoszyn":
      case "projektowanie-graficzne-krotoszyn":
        return "Krotoszyn";
      case "strony-internetowe-jarocin":
      case "projektowanie-graficzne-jarocin":
        return "Jarocin";
      default:
        return "Poznań";
    }
  };
  const formatCategoryName = (category) => {
    switch (category) {
      case "tworzenie-stron-internetowych":
        return "Strony internetowe";
      case "projektowanie-graficzne":
        return "Projektowanie Graficzne";
      case "tworzenie-logo":
        return "Tworzenie Logo";
      case "tworzenie-animacji":
        return "Tworzenie Animacji";
      case "pozycjonowanie-stron":
        return "Pozycjonowanie Stron";
      case "marketing-w-social-media":
        return "Marketing w Social Media";
      case "strony-internetowe-poznan":
        return "Strony internetowe";
      case "projektowanie-graficzne-poznan":
        return "Projektowanie Graficzne";
      case "strony-internetowe-kalisz":
        return "Strony internetowe";
      case "projektowanie-graficzne-kalisz":
        return "Projektowanie Graficzne";
      case "strony-internetowe-ostrow-wielkopolski":
        return "Strony internetowe";
      case "projektowanie-graficzne-ostrow-wielkopolski":
        return "Projektowanie Graficzne";
      case "strony-internetowe-gniezno":
        return "Strony internetowe";
      case "projektowanie-graficzne-gniezno":
        return "Projektowanie Graficzne";
      case "strony-internetowe-leszno":
        return "Strony internetowe";
      case "projektowanie-graficzne-leszno":
        return "Projektowanie Graficzne";
      case "strony-internetowe-konin":
        return "Strony internetowe";
      case "projektowanie-graficzne-konin":
        return "Projektowanie Graficzne";
      case "strony-internetowe-lubon":
        return "Strony internetowe";
      case "projektowanie-graficzne-lubon":
        return "Projektowanie Graficzne";
      case "strony-internetowe-wrzesnia":
        return "Strony internetowe";
      case "projektowanie-graficzne-wrzesnia":
        return "Projektowanie Graficzne";
      case "strony-internetowe-swarzedz":
        return "Strony internetowe";
      case "projektowanie-graficzne-swarzedz":
        return "Projektowanie Graficzne";
      case "strony-internetowe-krotoszyn":
        return "Strony internetowe";
      case "projektowanie-graficzne-krotoszyn":
        return "Projektowanie Graficzne";
      case "strony-internetowe-jarocin":
        return "Strony internetowe";
      case "projektowanie-graficzne-jarocin":
        return "Projektowanie Graficzne";
      default:
        return "Strony internetowe";
    }
  };

  const formatCityUrl = (city) => {
    switch (city) {
      case "tworzenie-stron-internetowych":
        return "oferta/tworzenie-stron-internetowych";
      case "strony-internetowe-poznan":
        return "oferta/strony-internetowe-poznan";
      case "strony-internetowe-kalisz":
        return "oferta/strony-internetowe-kalisz";
      case "strony-internetowe-ostrow-wielkopolski":
        return "oferta/strony-internetowe-ostrow-wielkopolski";
      case "strony-internetowe-gniezno":
        return "oferta/strony-internetowe-gniezno";
      case "strony-internetowe-leszno":
        return "oferta/strony-internetowe-leszno";
      case "strony-internetowe-konin":
        return "oferta/strony-internetowe-konin";
      case "strony-internetowe-lubon":
        return "oferta/strony-internetowe-lubon";
      case "strony-internetowe-wrzesnia":
        return "oferta/strony-internetowe-wrzesnia";
      case "strony-internetowe-swarzedz":
        return "oferta/strony-internetowe-swarzedz";
      case "strony-internetowe-krotoszyn":
        return "oferta/strony-internetowe-krotoszyn";
      case "strony-internetowe-jarocin":
        return "oferta/strony-internetowe-jarocin";
      case "projektowanie-graficzne":
        return "oferta/projektowanie-graficzne";
      case "oferta/projektowanie-graficzne":
        return "oferta/projektowanie-graficzne-poznan";
      case "projektowanie-graficzne-poznan":
        return "oferta/projektowanie-graficzne-poznan";
      case "projektowanie-graficzne-kalisz":
        return "oferta/projektowanie-graficzne-kalisz";
      case "projektowanie-graficzne-ostrow-wielkopolski":
        return "oferta/projektowanie-graficzne-ostrow-wielkopolski";
      case "projektowanie-graficzne-gniezno":
        return "oferta/projektowanie-graficzne-gniezno";
      case "projektowanie-graficzne-leszno":
        return "oferta/projektowanie-graficzne-leszno";
      case "projektowanie-graficzne-konin":
        return "oferta/projektowanie-graficzne-konin";
      case "projektowanie-graficzne-lubon":
        return "oferta/projektowanie-graficzne-lubon";
      case "projektowanie-graficzne-wrzesnia":
        return "oferta/projektowanie-graficzne-wrzesnia";
      case "projektowanie-graficzne-swarzedz":
        return "oferta/projektowanie-graficzne-swarzedz";
      case "projektowanie-graficzne-krotoszyn":
        return "oferta/projektowanie-graficzne-krotoszyn";
      case "projektowanie-graficzne-jarocin":
        return "oferta/projektowanie-graficzne-jarocin";
      case "tworzenie-logo":
        return "oferta/tworzenie-logo";
      case "tworzenie-animacji":
        return "oferta/tworzenie-animacji";
      case "pozycjonowanie-stron":
        return "oferta/pozycjonowanie-stron";
      case "marketing-w-social-media":
        return "oferta/marketing-w-social-media";
      default:
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Deskar - ${formatCategoryName(city)} ${formatCityName(
          city
        )}`}</title>
        <link
          rel="canonical"
          aria-label="deskar.pl"
          href={`https://deskar.pl/${formatCityUrl(city)}`}
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        ></meta>
        <meta name="viewport" content="width=device-width, minimum-scale=1.0" />
        <meta
          name="description"
          content="Specjalizujemy się w tworzeniu innowacyjnych stron internetowych w Poznaniu, które nie tylko przyciągają uwagę wizualną, ale również gwarantują efektywność i doskonałe doświadczenia użytkownika."
        />
        <meta
          name="keywords"
          content="Deskar, strony internetowe, projektowanie stron, tworzenie stron, Karwacki, strony wwww, projektowanie graficzne, loga, projektowanie loga, idetyfikacja wizualna, wizytówki, projektowanie wizytówki"
        />

        <meta property="og:locale" content="pl_PL" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Deskar - ${formatCategoryName(city)} ${formatCityName(
            city
          )}`}
        />
        <meta
          property="og:description"
          content="Specjalizujemy się w tworzeniu innowacyjnych stron internetowych w Poznaniu, które nie tylko przyciągają uwagę wizualną, ale również gwarantują efektywność i doskonałe doświadczenia użytkownika."
        />
        <meta
          property="og:url"
          content={`https://www.deskar.pl/${formatCityUrl(city)}`}
        />
        <meta
          property="og:site_name"
          content={`Deskar - ${formatCategoryName(city)} ${formatCityName(
            city
          )}`}
        />
        <meta
          property="og:image"
          content={"https://www.deskar.pl" + metaLogo}
        />
        <meta property="og:image:width" content="680" />
        <meta property="og:image:height" content="465" />
        <meta property="og:image:type" content="image/png" />
        <meta name="author" content="Deskar" />
        <meta
          name="google-site-verification"
          content="v2VHB88HtZjc-nBkDZvxkaKRApnVtm-v9Mfdg9wn8Ac"
        />
      </Helmet>
      {filteredData && (
        <section className="main-banner-website" key={filteredData.id}>
          <div className={`main-banner-bg ${filteredData.mainBannerBg}`}></div>
          <div className="main-banner-website-blur"></div>

          <div className="main-banner-website-content ">
            <div className="main-banner-website-description">
              <h1>
                {filteredData.mainBannerTittle} {formatCityName(city)}
              </h1>
              <p>{filteredData.mainBannerDescription}</p>
              <div className="more-button">
                <Link
                  href="#description"
                  to="description"
                  aria-label="description"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={1000}
                >
                  ZOBACZ WIĘCEJ
                </Link>
              </div>
            </div>

            <div
              className={`main-banner-image ${filteredData.mainBannerPhoto}`}
            ></div>
          </div>
        </section>
      )}
    </>
  );
};

export default MainBannerOffer;
