import React, { useState, useRef } from "react";
import anime from "animejs/lib/anime.es.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP, ScrollTrigger);
const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const faqRef = useRef(null);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: faqRef.current,
      start: "top 40%",
      markers: false,
      scrub: 3,
      once: true,
      onEnter: () => {
        anime({
          targets: ".faq-section h4",
          translateY: [200, 0],
          opacity: 1,
          duration: 1000,
          easing: "easeInOutExpo",
        });

        anime({
          targets: ".faq-section  .faq",
          translateY: [200, 0],
          opacity: 1,
          duration: 2000,
          delay: function (el, i, l) {
            return i * 200;
          },
          endDelay: function (el, i, l) {
            return (l - i) * 200;
          },
          easing: "easeInOutExpo",
        });
      },
    });
  }, []);
  return (
    <section className="faq-section" ref={faqRef}>
      <h4 className="title heading">FAQs - Najczęstsze pytania</h4>
      <div className="faq">
        <div className="question" onClick={() => toggleFaq(0)}>
          <h4 className="small-heading">
            Czym zajmuje się Wasza firma - Deskar?
          </h4>
          <svg
            id="Warstwa_1"
            data-name="Warstwa 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 42.43 42.43"
            className={activeIndex === 0 ? "cls-1 active" : "cls-1"}
          >
            <defs></defs>
            <rect
              x="517"
              y="243"
              width="30"
              height="30"
              transform="translate(-537.4 214.96) rotate(-45)"
            />
          </svg>
        </div>
        <div className={activeIndex === 0 ? "answer active  " : "answer"}>
          <p className="text">
            Nasza firma specjalizuje się w kompleksowych usługach związanych z
            <a href="/oferta/tworzenie-stron-internetowych">
              tworzeniem stron internetowych,
            </a>
            <a href="/oferta/projektowanie-graficzne">
              projektowaniem graficznym,
            </a>
            <a href="/oferta/pozycjonowanie-stron">pozycjonowaniem SEO</a>
            oraz
            <a href="/oferta/projektowanie-graficzne">budowaniem marki.</a>
            Dzięki naszemu doświadczeniu i zaangażowaniu pomagamy naszym
            klientom w osiągnięciu sukcesu online poprzez skuteczne strategie
            internetowe.
          </p>
        </div>
      </div>
      <div className="faq">
        <div className="question" onClick={() => toggleFaq(1)}>
          <h4 className="small-heading">
            Jakie są korzyści z posiadania profesjonalnej strony internetowej?
          </h4>
          <svg
            id="Warstwa_1"
            data-name="Warstwa 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 42.43 42.43"
            className={activeIndex === 1 ? "cls-1 active" : "cls-1"}
          >
            <defs></defs>
            <rect
              x="517"
              y="243"
              width="30"
              height="30"
              transform="translate(-537.4 214.96) rotate(-45)"
            />
          </svg>
        </div>
        <div className={activeIndex === 1 ? "answer active" : "answer"}>
          <p className="text">
            Posiadanie{" "}
            <a href="/oferta/tworzenie-stron-internetowych">
              profesjonalnej strony internetowej
            </a>{" "}
            może przynieść wiele korzyści. Wyróżniamy między innymi
            <a href="/oferta/pozycjonowanie-stron">zwiększenie widoczności</a>w
            Internecie,
            <a href="/oferta/pozycjonowanie-stron">
              budowanie zaufania wśród klientów,
            </a>
            możliwość dotarcia do szerszej grupy odbiorców oraz{" "}
            <a href="/oferta/projektowanie-graficzne">
              poprawę wizerunku marki.
            </a>{" "}
            Dzięki odpowiednio zaprojektowanej stronie internetowej można
            efektywnie promować produkty i usługi oraz prowadzić interakcję z
            klientami.
          </p>
        </div>
      </div>
      <div className="faq">
        <div className="question" onClick={() => toggleFaq(2)}>
          <h4 className="small-heading">
            Czy oferujecie również usługi związane z grafiką i projektowaniem
            graficznym?
          </h4>
          <svg
            id="Warstwa_1"
            data-name="Warstwa 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 42.43 42.43"
            className={activeIndex === 2 ? "cls-1 active" : "cls-1"}
          >
            <defs></defs>
            <rect
              x="517"
              y="243"
              width="30"
              height="30"
              transform="translate(-537.4 214.96) rotate(-45)"
            />
          </svg>
        </div>
        <div className={activeIndex === 2 ? "answer active" : "answer"}>
          <p className="text">
            Tak, nasza firma oferuje kompleksowe usługi związane z{" "}
            <a href="/oferta/projektowanie-graficzne">
              grafiką i projektowaniem graficznym
            </a>
            . Nasz zespół projektantów graficznych pracuje nad stworzeniem
            atrakcyjnego i profesjonalnego wyglądu{" "}
            <a href="/oferta/tworzenie-stron-internetowych">
              Twojej strony internetowej, logo, materiałów promocyjnych
            </a>{" "}
            oraz innych{" "}
            <a href="/oferta/projektowanie-graficzne">elementów wizualnych,</a>{" "}
            które mogą przyciągnąć uwagę klientów i wyróżnić Twoją markę.
          </p>
        </div>
      </div>

      <div className="faq">
        <div className="question" onClick={() => toggleFaq(3)}>
          <h4 className="small-heading">
            Czym jest pozycjonowanie SEO i dlaczego jest ważne dla mojej strony
            internetowej?
          </h4>
          <svg
            id="Warstwa_1"
            data-name="Warstwa 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 42.43 42.43"
            className={activeIndex === 3 ? "cls-1 active" : "cls-1"}
          >
            <defs></defs>
            <rect
              x="517"
              y="243"
              width="30"
              height="30"
              transform="translate(-537.4 214.96) rotate(-45)"
            />
          </svg>
        </div>
        <div className={activeIndex === 3 ? "answer active" : "answer"}>
          <p className="text">
            Pozycjonowanie SEO to proces{" "}
            <a href="/oferta/pozycjonowanie-stron">
              optymalizacji strony internetowej
            </a>{" "}
            w taki sposób, aby była ona{" "}
            <a href="/oferta/pozycjonowanie-stron">lepiej widoczna</a> w
            wynikach wyszukiwania Google i innych wyszukiwarek internetowych.
            Jest to istotne, ponieważ większość użytkowników Internetu korzysta
            z wyszukiwarek do znalezienia potrzebnych informacji, produktów i
            usług. Poprzez{" "}
            <a href="/oferta/pozycjonowanie-stron">
              skuteczne pozycjonowanie SEO
            </a>{" "}
            możesz zwiększyć ruch na swojej stronie, dotrzeć do nowych klientów
            i zwiększyć sprzedaż.
          </p>
        </div>
      </div>
      <div className="faq">
        <div className="question" onClick={() => toggleFaq(4)}>
          <h4 className="small-heading">
            Czy mogę liczyć na wsparcie w zakresie budowy marki i poprawy
            wyników mojej marki?
          </h4>
          <svg
            id="Warstwa_1"
            data-name="Warstwa 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 42.43 42.43"
            className={activeIndex === 4 ? "cls-1 active" : "cls-1"}
          >
            <defs></defs>
            <rect
              x="517"
              y="243"
              width="30"
              height="30"
              transform="translate(-537.4 214.96) rotate(-45)"
            />
          </svg>
        </div>
        <div className={activeIndex === 4 ? "answer active" : "answer"}>
          <p className="text">
            Tak, nasza firma oferuje{" "}
            <a href="/oferta/projektowanie-graficzne">
              wsparcie w zakresie budowy marki
            </a>{" "}
            i{" "}
            <a href="/oferta/projektowanie-graficzne">
              poprawy wyników Twojej marki.
            </a>{" "}
            Działamy w oparciu o strategie, które pomagają budować świadomość
            marki,{" "}
            <a href="/oferta/pozycjonowanie-stron">
              zwiększać zaangażowanie klientów
            </a>{" "}
            i rozwijać lojalność wśród odbiorców. Nasi eksperci są gotowi pomóc
            Ci w osiągnięciu sukcesu online i{" "}
            <a href="/oferta/pozycjonowanie-stron">zbudowaniu trwałej marki</a>{" "}
            w internecie.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Faq;
