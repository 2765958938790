import React, { useEffect } from "react";
import anime from "animejs/lib/anime.es.js";
import UseFetchProjects from "../UseFetch/UseFetchProjects";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import Bglineproject from "../Background/Bglineproject";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const Project = () => {
  const { data, error } = UseFetchProjects(
    "https://db-json-gamma.vercel.app/project"
  );

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: ".offer", // Trigger element
      start: "80% 40%", // Start point of the trigger
      markers: false, // Whether to display debug markers
      scrub: 3, // Whether animation should scrub or not
      once: true,
      onEnter: () => {
        anime({
          targets: ".heading-small-project",
          translateY: [200, 0],
          opacity: 1,
          duration: 1000,
          easing: "easeInOutExpo",
        });

        anime({
          targets: ".project-main",
          translateY: [500, 0],
          opacity: 1,
          duration: 1000,
          easing: "easeInOutExpo",
        });
      },
    });
  }, [data]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <section className="project">
        <Bglineproject />
        <div className="heading-small-project">
          <p className="small-text">PROJEKTY</p>
        </div>

        {data &&
          data.length > 0 &&
          data.slice(0, 5).map((project) => (
            <div className="project-main" key={project.id}>
              <div className="project-description">
                <div className="top">
                  <h3 className="title medium-heading">{project.title}</h3>
                  <p className="category small-text">
                    {project.category.join(", ")}
                  </p>
                </div>
                <div className="bottom">
                  <p className="tittle-services extra-small-text">Usługi</p>
                  {project.services &&
                    project.services.map((service) => (
                      <p className="extra-small-text" key={service}>
                        {service}
                      </p>
                    ))}
                </div>

                <div className="project-button">
                  <a
                    href={project.link}
                    aria-label={project.title}
                    className="small-text"
                  >
                    ZOBACZ
                  </a>
                </div>
              </div>
              <div className="project-gallery">
                <a
                  href={project.link}
                  aria-label={project.title}
                  className="tile"
                  style={{
                    backgroundImage: `url(/photos/${project.image})`,
                  }}
                ></a>
                <a
                  href={project.link}
                  className="small-tile"
                  aria-label={project.title}
                  style={{
                    backgroundImage: `url(/photos/${project.image_phone})`,
                  }}
                ></a>
              </div>
            </div>
          ))}
        <div className="more-project">
          <div className="more-button">
            <div className="square"></div>
            <a href="/portfolio" className="small-text">
              ZOBACZ WIĘCEJ
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Project;
