import React, { useState, useEffect } from "react";
import UseFetchProjects from "./UseFetch/UseFetchProjects";
import anime from "animejs/lib/anime.es.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Bgline from "./Background/Bgline";

const Portfolio = () => {
  const { data, error } = UseFetchProjects(
    "https://db-json-gamma.vercel.app/project"
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [totalProjects, setTotalProjects] = useState(0);
  const [categoryProjects, setCategoryProjects] = useState({});

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    if (data) {
      const categoryCounts = data.reduce((counts, project) => {
        project.category.forEach((category) => {
          counts[category] = (counts[category] || 0) + 1;
        });
        return counts;
      }, {});
      setCategoryProjects(categoryCounts);
      setTotalProjects(data.length);

      // Animacja elementów kategorii i tytułu po załadowaniu danych
      anime({
        targets: ".portfolio-category",
        translateX: [-1000, 0],
        duration: 2000,
        easing: "easeInOutExpo",
      });

      anime({
        targets: ".portfolio-main-tittle",
        translateX: [1500, 0],
        duration: 2000,
        easing: "easeInOutExpo",
      });

      anime({
        targets: ".portfolio-min-projects",
        opacity: 1,
        duration: 3000,
        easing: "easeInOutExpo",
      });

      gsap.registerPlugin(ScrollTrigger);

      ScrollTrigger.create({
        trigger: ".portfolio-min-projects",
        start: "top 50%",
        end: "bottom 95%",
        markers: false,
        scrub: 3,

        onEnter: () => {
          anime({
            targets: ".down-category ul",
            translateY: [500, 0],
            opacity: 1,
            duration: 500,
            easing: "easeInOutExpo",
          });
        },
        onEnterBack: () => {
          anime({
            targets: ".down-category ul",
            translateY: [500, 0],
            opacity: 1,
            duration: 500,
            easing: "easeInOutExpo",
          });
        },
        onLeave: () => {
          anime({
            targets: ".down-category ul",
            translateY: [0, 500],
            opacity: 0,
            duration: 500,
            easing: "easeInOutExpo",
          });
        },
        onLeaveBack: () => {
          anime({
            targets: ".down-category ul",
            translateY: [0, 500],
            opacity: 0,
            duration: 500,
            easing: "easeInOutExpo",
          });
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      // Animacja elementów projektu po zmianie filtra
      anime({
        targets: ".project-main",
        opacity: [0, 1],
        translateY: [40, 0],
        duration: 800,
        easing: "easeInOutQuad",
        delay: anime.stagger(100, { start: 150 }),
      });
    }
  }, [selectedCategory, data]);

  return (
    <>
      <Bgline />
      <section className="portfolio">
        <div className="portfolio-category">
          <div>
            <p className="big-text ">Filter kategorii</p>
            <ul>
              <li
                className={
                  selectedCategory === null
                    ? "selected small-heading"
                    : "small-heading"
                }
                onClick={() => handleCategoryClick(null)}
              >
                Wszystkie{" "}
                <sup className="extra-small-text">{totalProjects}</sup>
              </li>
              {Object.keys(categoryProjects).map((category) => (
                <li
                  key={category}
                  className={
                    selectedCategory === category
                      ? "selected small-heading]"
                      : "small-heading"
                  }
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}{" "}
                  <sup className="extra-small-text">
                    {categoryProjects[category]}
                  </sup>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="portfolio-main-tittle">
          <h2 className="big-heading ">Nasze prace</h2>
          <div className="description">
            <p className="text">
              Z niecierpliwością oczekujemy na kontakt mailowy lub telefoniczny
              od Ciebie w sprawie potencjalnych współprac, zapytań, dołączenia
              do naszego zespołu, czy też po prostu na przyjazną i kreatywną
              dyskusję.
            </p>
          </div>
        </div>
      </section>
      <section className="portfolio-min-projects">
        {data &&
          data
            .filter((project) =>
              selectedCategory
                ? project.category.includes(selectedCategory)
                : true
            )
            .map((project) => (
              <a
                href={project.link}
                aria-label={project.title}
                className="project-main"
                key={project.id}
              >
                <div className="project-gallery">
                  <div
                    className="tile"
                    style={{
                      backgroundImage: `url(/photos/${project.image})`,
                    }}
                  ></div>
                </div>
                <div className="project-description">
                  <h2 className="title text">{project.title}</h2>
                  <p className="category small-text">
                    {project.category.join(", ")}
                  </p>
                </div>
              </a>
            ))}
        <div className="down-category">
          <ul>
            <li
              className={selectedCategory === null ? "selected text" : "text"}
              onClick={() => handleCategoryClick(null)}
            >
              Wszystkie <sup className="extra-small-text">{totalProjects}</sup>
            </li>
            {Object.keys(categoryProjects).map((category) => (
              <li
                key={category}
                className={
                  selectedCategory === category ? "selected text" : "text"
                }
                onClick={() => handleCategoryClick(category)}
              >
                {category}{" "}
                <sup className="extra-small-text">
                  {categoryProjects[category]}
                </sup>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
